import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./style.scss";
import Main from "./app/pages/main/main";
import { DashborRoutes } from "./app/routes/dashbord-routes";
import { AuthRoutes } from "./app/routes/auth-routes";
import { ThemeProvider } from "@mui/material/styles";
import { lightTheme, darkTheme } from "./app/shared/theme/main-theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageNotFound from "./app/shared/components/page-not-found/page-not-found";
import AuthMain from "./app/auth/auth-main";

const route = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [...DashborRoutes],
    errorElement: <PageNotFound />,
  },

  {
    path: "auth",
    element: <AuthMain />,
    children: [...AuthRoutes],
    errorElement: <PageNotFound />,
  },
]);
const theme = lightTheme;
function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={route} />
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
