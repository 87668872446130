import React from "react";

const Icon = ({ name, color, label }) => {
  let icon = null;

  switch (name) {
    case "breadcrumbs":
      icon = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2L2 7L12 12L22 7L12 2Z"
            stroke="#6f8999"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 17L12 22L22 17"
            stroke="#6f8999"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 12L12 17L22 12"
            stroke="#6f8999"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
      break;

    case "breadcrumbsLayers":
      icon = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.8111 18.707L9.40015 17.293L14.6891 12L9.40015 6.70697L10.8151 5.29297L16.1001 10.586C16.4751 10.961 16.6857 11.4696 16.6857 12C16.6857 12.5303 16.4751 13.0389 16.1001 13.414L10.8111 18.707Z"
            fill="#5F5F5F"
          />
        </svg>
      );
      break;

    case "loginIcon":
      icon = (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.9321 3H19.9321C20.4626 3 20.9713 3.21071 21.3463 3.58579C21.7214 3.96086 21.9321 4.46957 21.9321 5V19C21.9321 19.5304 21.7214 20.0391 21.3463 20.4142C20.9713 20.7893 20.4626 21 19.9321 21H15.9321"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.9321 17L15.9321 12L10.9321 7"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.9321 12H3.93213"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
      break;

    case "passwordEye":
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill={color}
            d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 7.449-11.985 7.449c-7.18 0-12.015-7.449-12.015-7.449s4.446-6.551 12.015-6.551c7.694 0 11.985 6.551 11.985 6.551zm-7 .449c0-2.761-2.238-5-5-5-2.761 0-5 2.239-5 5 0 2.762 2.239 5 5 5 2.762 0 5-2.238 5-5z"
          />
        </svg>
      );
      break;

    case "passwordEyeHide":
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill={color}
            d="M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z"
          />
        </svg>
      );
      break;
    case "filterIcon":
      icon = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill=""
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.0003 20C10.7169 20 10.4793 19.904 10.2873 19.712C10.0953 19.52 9.99959 19.2827 10.0003 19V13L4.20025 5.6C3.95025 5.26667 3.91259 4.91667 4.08725 4.55C4.26192 4.18333 4.56625 4 5.00025 4H19.0003C19.4336 4 19.7379 4.18333 19.9133 4.55C20.0886 4.91667 20.0509 5.26667 19.8003 5.6L14.0003 13V19C14.0003 19.2833 13.9043 19.521 13.7123 19.713C13.5203 19.905 13.2829 20.0007 13.0003 20H11.0003Z"
            fill="#003554"
          />
        </svg>
      );
      break;

    case "downDropdown":
      icon = (
        <svg
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill=""
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.94828 1.29L5.82828 5.17L9.70828 1.29C9.80087 1.19742 9.91078 1.12398 10.0317 1.07387C10.1527 1.02377 10.2824 0.997978 10.4133 0.997978C10.5442 0.997978 10.6739 1.02377 10.7948 1.07387C10.9158 1.12398 11.0257 1.19742 11.1183 1.29C11.2109 1.38258 11.2843 1.49249 11.3344 1.61346C11.3845 1.73442 11.4103 1.86407 11.4103 1.995C11.4103 2.12593 11.3845 2.25558 11.3344 2.37654C11.2843 2.49751 11.2109 2.60742 11.1183 2.7L6.52828 7.29C6.43577 7.3827 6.32588 7.45625 6.20491 7.50643C6.08393 7.55661 5.95425 7.58244 5.82328 7.58244C5.69232 7.58244 5.56263 7.55661 5.44166 7.50643C5.32069 7.45625 5.2108 7.3827 5.11828 7.29L0.528284 2.7C0.43558 2.60749 0.362032 2.4976 0.311851 2.37662C0.26167 2.25565 0.23584 2.12597 0.23584 1.995C0.23584 1.86403 0.26167 1.73435 0.311851 1.61337C0.362032 1.4924 0.43558 1.38251 0.528284 1.29C0.918284 0.909999 1.55828 0.899999 1.94828 1.29Z"
            fill={color}
          />
        </svg>
      );
      break;
    case "closeIcon":
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M18.707 6.70685L17.293 5.29285L12 10.5858L6.70697 5.29285L5.29297 6.70685L10.586 11.9998L5.29297 17.2928L6.70697 18.7068L12 13.4138L17.293 18.7068L18.707 17.2928L13.414 11.9998L18.707 6.70685Z"
            fill="red"
          />
        </svg>
      );
      break;

    case "upDropdown":
      icon = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill=""
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.8798 14.71L11.9998 10.83L8.11984 14.71C8.02726 14.8026 7.91735 14.876 7.79638 14.9261C7.67542 14.9762 7.54577 15.002 7.41484 15.002C7.28391 15.002 7.15426 14.9762 7.0333 14.9261C6.91233 14.876 6.80242 14.8026 6.70984 14.71C6.61726 14.6174 6.54382 14.5075 6.49372 14.3865C6.44361 14.2656 6.41782 14.1359 6.41782 14.005C6.41782 13.8741 6.44361 13.7444 6.49372 13.6235C6.54382 13.5025 6.61726 13.3926 6.70984 13.3L11.2998 8.70999C11.3924 8.61729 11.5022 8.54374 11.6232 8.49356C11.7442 8.44338 11.8739 8.41755 12.0048 8.41755C12.1358 8.41755 12.2655 8.44338 12.3865 8.49356C12.5074 8.54374 12.6173 8.61729 12.7098 8.70999L17.2998 13.3C17.3925 13.3925 17.4661 13.5024 17.5163 13.6234C17.5665 13.7443 17.5923 13.874 17.5923 14.005C17.5923 14.136 17.5665 14.2656 17.5163 14.3866C17.4661 14.5076 17.3925 14.6175 17.2998 14.71C16.9098 15.09 16.2698 15.1 15.8798 14.71Z"
            fill={color}
          />
        </svg>
      );
      break;

    case "download":
      icon = (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill=""
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 11.575C7.86667 11.575 7.74167 11.5543 7.625 11.513C7.50834 11.4717 7.4 11.4007 7.3 11.3L3.7 7.7C3.51667 7.51667 3.425 7.28334 3.425 7C3.425 6.71667 3.51667 6.48334 3.7 6.3C3.88333 6.11667 4.121 6.021 4.413 6.013C4.705 6.005 4.94234 6.09234 5.125 6.275L7 8.15V1C7 0.71667 7.096 0.479004 7.288 0.287004C7.48 0.0950036 7.71734 -0.000663206 8 3.4602e-06C8.28334 3.4602e-06 8.521 0.0960036 8.713 0.288004C8.905 0.480004 9.00067 0.717337 9 1V8.15L10.875 6.275C11.0583 6.09167 11.296 6.004 11.588 6.012C11.88 6.02 12.1173 6.116 12.3 6.3C12.4833 6.48334 12.575 6.71667 12.575 7C12.575 7.28334 12.4833 7.51667 12.3 7.7L8.7 11.3C8.6 11.4 8.49167 11.471 8.375 11.513C8.25834 11.555 8.13334 11.5757 8 11.575ZM2 16C1.45 16 0.979002 15.804 0.587002 15.412C0.195002 15.02 -0.000664969 14.5493 1.69779e-06 14V12C1.69779e-06 11.7167 0.0960018 11.479 0.288002 11.287C0.480002 11.095 0.717335 10.9993 1 11C1.28333 11 1.521 11.096 1.713 11.288C1.905 11.48 2.00067 11.7173 2 12V14H14V12C14 11.7167 14.096 11.479 14.288 11.287C14.48 11.095 14.7173 10.9993 15 11C15.2833 11 15.521 11.096 15.713 11.288C15.905 11.48 16.0007 11.7173 16 12V14C16 14.55 15.804 15.021 15.412 15.413C15.02 15.805 14.5493 16.0007 14 16H2Z"
            fill={color}
          />
        </svg>
      );
      break;
    case "share":
      icon = (
        <svg
          width="16"
          height="18"
          viewBox="0 0 16 18"
          fill=""
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 17.3333C12.3056 17.3333 11.7153 17.0903 11.2292 16.6042C10.7431 16.1181 10.5 15.5278 10.5 14.8333C10.5 14.7361 10.5069 14.6353 10.5208 14.5308C10.5347 14.4264 10.5556 14.3328 10.5833 14.25L4.70833 10.8333C4.47222 11.0417 4.20833 11.205 3.91667 11.3233C3.625 11.4417 3.31944 11.5006 3 11.5C2.30556 11.5 1.71528 11.2569 1.22917 10.7708C0.743056 10.2847 0.5 9.69445 0.5 9C0.5 8.30556 0.743056 7.71528 1.22917 7.22917C1.71528 6.74306 2.30556 6.5 3 6.5C3.31944 6.5 3.625 6.55917 3.91667 6.67751C4.20833 6.79584 4.47222 6.95889 4.70833 7.16667L10.5833 3.75C10.5556 3.66667 10.5347 3.57306 10.5208 3.46917C10.5069 3.36528 10.5 3.26445 10.5 3.16667C10.5 2.47223 10.7431 1.88195 11.2292 1.39584C11.7153 0.909727 12.3056 0.666672 13 0.666672C13.6944 0.666672 14.2847 0.909727 14.7708 1.39584C15.2569 1.88195 15.5 2.47223 15.5 3.16667C15.5 3.86112 15.2569 4.45139 14.7708 4.9375C14.2847 5.42362 13.6944 5.66667 13 5.66667C12.6806 5.66667 12.375 5.60778 12.0833 5.49001C11.7917 5.37223 11.5278 5.20889 11.2917 5L5.41667 8.41667C5.44444 8.50001 5.46528 8.59389 5.47917 8.69834C5.49306 8.80278 5.5 8.90334 5.5 9C5.5 9.09723 5.49306 9.19806 5.47917 9.3025C5.46528 9.40695 5.44444 9.50056 5.41667 9.58334L11.2917 13C11.5278 12.7917 11.7917 12.6286 12.0833 12.5108C12.375 12.3931 12.6806 12.3339 13 12.3333C13.6944 12.3333 14.2847 12.5764 14.7708 13.0625C15.2569 13.5486 15.5 14.1389 15.5 14.8333C15.5 15.5278 15.2569 16.1181 14.7708 16.6042C14.2847 17.0903 13.6944 17.3333 13 17.3333Z"
            fill={color}
          />
        </svg>
      );
      break;
    case "editIcon":
      icon = (
        <svg
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="14.4" cy="14.4" r="13.4" fill="#003554" stroke="white" />
          <path
            d="M15.9519 8.86241L20.0007 12.9112L12.0502 20.8617C11.8287 21.0832 11.5532 21.243 11.251 21.3255L7.15844 22.4416C6.71054 22.5638 6.29955 22.1528 6.42171 21.7049L7.53786 17.6123C7.62028 17.3101 7.78014 17.0346 8.00164 16.8131L15.9519 8.86241ZM21.6248 7.23849C22.7428 8.35646 22.7428 10.169 21.6248 11.287L20.8487 12.0624L16.7999 8.01441L17.5763 7.23849C18.6943 6.12052 20.5069 6.12052 21.6248 7.23849Z"
            fill="white"
          />
        </svg>
      );
      break;

    case "replyIcon":
      icon = (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill=""
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5981 4.53687C10.1456 3.47747 9.34119 2.60682 8.32081 2.07211C7.30043 1.5374 6.12671 1.37143 4.99808 1.60227C3.86945 1.83312 2.85516 2.4466 2.12667 3.33901C1.39818 4.23142 1.0002 5.34801 1 6.5V10.625C1 10.8571 1.09219 11.0796 1.25628 11.2437C1.42038 11.4078 1.64294 11.5 1.875 11.5H5.41687C5.80705 12.391 6.44821 13.1492 7.26207 13.6819C8.07593 14.2147 9.02729 14.4989 10 14.5H14.125C14.3571 14.5 14.5796 14.4078 14.7437 14.2437C14.9078 14.0796 15 13.8571 15 13.625V9.5C14.9998 8.27766 14.5518 7.09776 13.7407 6.18328C12.9296 5.2688 11.8117 4.6831 10.5981 4.53687ZM14 13.5H10C9.2927 13.4992 8.5982 13.3113 7.987 12.9553C7.37581 12.5993 6.86968 12.0879 6.52 11.4731C7.20482 11.4021 7.86756 11.1902 8.46663 10.8509C9.06569 10.5115 9.58813 10.052 10.0012 9.50115C10.4142 8.95031 10.7089 8.32003 10.8668 7.64988C11.0247 6.97973 11.0424 6.28418 10.9187 5.60687C11.7958 5.81385 12.5773 6.31092 13.1365 7.01752C13.6957 7.72412 14 8.59886 14 9.5V13.5Z"
            fill="#003554"
          />
        </svg>
      );
      break;

    case "sendIcon":
      icon = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill=""
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5489 20.8904C14.4075 21.2203 14.1628 21.4235 13.8149 21.4999C13.467 21.5763 13.1637 21.485 12.9049 21.2262L10.2709 18.5923C10.1059 18.4273 10.0057 18.2328 9.97039 18.0089C9.93503 17.785 9.98218 17.5728 10.1118 17.3725L13.9655 10.9732L7.56623 14.8269C7.36588 14.9566 7.15375 15.0037 6.92983 14.9683C6.70591 14.933 6.51146 14.8328 6.34647 14.6678L3.71249 12.0338C3.45322 11.7746 3.36177 11.471 3.43814 11.1231C3.5145 10.7752 3.71792 10.5308 4.04837 10.3898L19.534 4.09657C19.9583 3.93158 20.3236 4.00229 20.63 4.3087C20.9364 4.61512 21.0071 4.98046 20.8422 5.40472L14.5489 20.8904Z"
            fill="#003554"
          />

          <defs>
            <clipPath id="clip0_875_1570">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
      break;

    case "emojiIcon":
      icon = (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill=""
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM8.5 8C9.33 8 10 8.67 10 9.5C10 10.33 9.33 11 8.5 11C7.67 11 7 10.33 7 9.5C7 8.67 7.67 8 8.5 8ZM16.71 14.72C15.8 16.67 14.04 18 12 18C9.96 18 8.2 16.67 7.29 14.72C7.13 14.39 7.37 14 7.74 14H16.26C16.63 14 16.87 14.39 16.71 14.72ZM15.5 11C14.67 11 14 10.33 14 9.5C14 8.67 14.67 8 15.5 8C16.33 8 17 8.67 17 9.5C17 10.33 16.33 11 15.5 11Z"
            fill="#797979"
          />
        </svg>
      );
      break;

    case "backArrow":
      icon = (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 7.21V9H13C13.55 9 14 9.45 14 10C14 10.55 13.55 11 13 11H10V12.79C10 13.24 9.46 13.46 9.15 13.14L6.36 10.35C6.16 10.15 6.16 9.84 6.36 9.64L9.15 6.85C9.2205 6.7809 9.30987 6.73421 9.40686 6.71582C9.50384 6.69743 9.60411 6.70816 9.69501 6.74665C9.7859 6.78515 9.86337 6.8497 9.91765 6.93215C9.97193 7.01461 10.0006 7.11129 10 7.21Z"
            fill="white"
          />
        </svg>
      );
      break;
    default:
      icon = null;
      break;
  }

  return icon;
};

export default Icon;
