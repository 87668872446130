import { Outlet } from "react-router-dom";
import { SignupDataProvider } from "../shared/services/context/subcontext";

const AuthMain = () => {
  return (
    <SignupDataProvider>
      <Outlet />
    </SignupDataProvider>
  );
};

export default AuthMain;
