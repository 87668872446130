import Icon from "../../../shared/components/icon/icon";
import "./pdf-view.scss";

import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Popover,
  TextField,
} from "@mui/material";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import paperlogo from "../../../assets/paper.svg";
import EmojiPicker from "emoji-picker-react";
import ApiInterfaceServices from "../../../shared/services/api-interface";
import { authEndpoits } from "../../../utility/endpoints";
import { showToastErrorMessage } from "../../../shared/services/common-services";
import { TibButton } from "../../../shared/components/button/button";
import getTimeAgo from "../../../shared/services/context/timecompare";
import { useAxiosLoader } from "../../../shared/interceptores/api-interceptor";
import { BreadCrumbContext } from "../../../shared/services/context/subcontext";
import LoginPopup from "../../../shared/components/login-popup/login-popup";
import { getLocalStorageItem } from "../../../utility/utility";

//PDFjs worker from an external cdn
const url =
  "https://cors-anywhere.herokuapp.com/http://www.pdf995.com/samples/pdf.pdf";

/**
 *
 * @returns pdf view page
 */
const PdfView = () => {
  const BreadCrumbsData = [
    { label: "Dashboard", url: "dashboard" },
    { label: "QuestionPaperList", url: "dashboard/questionpaper-list" },
    { label: "Paper", url: "" },
  ];

  // Using a context hook to access Breadcrumbs data
  const BreadCrumbs = useContext(BreadCrumbContext);
  // Setting up PDF.js worker source
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  // State hook to manage chat input data
  const [chatInput, setChatInput] = useState();

  // State hook to manage paper data
  const [paperData, setPaperData] = useState();

  // State hook to manage the URL of the PDF
  const [pdfUrl, setPdfUrl] = useState();

  // State hook to manage the visibility of the emoji picker
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  // State hook to manage the number of pages in the PDF
  const [numPages, setNumPages] = useState();

  // State hook to manage comment data
  const [commentData, setCommentData] = useState([]);

  // State hook to store the parent ID for a reply
  const [replyPrentId, setReplyParentId] = useState("");

  // Reference to the scroll container
  const scrollContainerRef = useRef(null);

  // State hook to manage the current page number of the PDF
  const [currPage, setCurrPage] = useState(1);

  // State hook to manage the total number of pages in the PDF
  const [totalPage, setTotalPage] = useState();

  // State hook to manage the checkbox checked state
  const [isChecked, setIsChecked] = useState(false);

  // State hook to manage user data
  const [user, setUser] = useState();

  // State hook to manage the user ID
  const [userID, setUserId] = useState("");

  // State hook to manage the click ID
  const [clickId, setClickId] = useState("");

  // State hook to manage reply data
  const [replyData, setReplyData] = useState([]);

  // State hook to manage PDF metadata
  const [metaData, setMetaData] = useState();

  // State hook to manage the scale of the PDF
  const [scale, setScale] = useState(1.5);

  // State hook to manage login popup
  const [open, setOpen] = useState(false);

  // Custom hook to track loading state when making API requests
  const [isloading] = useAxiosLoader();

  // Extracting the URL from the browser's address bar
  const url = window.location.href;
  // Creating a URL object
  const urlObj = new URL(url);
  // Extracting the 'id' parameter value
  const id = urlObj.searchParams.get("id");

  // Get User Name form local storage
  const userName = getLocalStorageItem("user");

  // Creating an instance of the API service
  const apiService = new ApiInterfaceServices();

  useEffect(() => {
    Promise.all([getPaperCommentData(), getPaperData(), getUserData()]);
    BreadCrumbs.setBreadCrumb(BreadCrumbsData);
  }, []);
  useEffect(() => {
    getPaperCommentData();
  }, [currPage, userID]);

  /**
   * api call (get) paper
   */
  const getPaperData = async () => {
    try {
      const response = await apiService.get(authEndpoits.listPapers, true);
      const data = response.data.data;

      const desiredPaperUuid = id;
      const result = data.filter(
        (item) => item.paper_uuid === desiredPaperUuid
      );
      setPaperData(result[0]);
      getPdfUrl(result[0].file_uuid);
    } catch (error) {
      showToastErrorMessage(error.message);
    }
  };

  /**
   * api call (get) comments
   */
  const getPaperCommentData = async () => {
    const params = {
      paper_uuid: id,
      per_page: "8",
      user_uuid: userID,
    };
    try {
      // Initialize an array to store the combined data for all pages
      let combinedData = [];

      // Loop through all pages up to the current page
      for (let page = 1; page <= currPage; page++) {
        // Update the page parameter
        params.page = page.toString();
        const response = await apiService.get(
          authEndpoits.commet,
          false,
          params
        );

        const comment = response.data.data;

        // Combine the data for the current page with the existing data
        combinedData = [...combinedData, ...comment];
        // Set the metadata and total pages as usual
        const metaData = response.data.meta;
        setMetaData(metaData);
        const totalPages = Math.ceil(metaData.total_rows / metaData.per_page);
        setTotalPage(totalPages);
      }

      // Set the combined data for all pages
      setCommentData(combinedData);
    } catch (error) {
      showToastErrorMessage(error.message);
    }
  };

  /**
   * api call to get pdf file data
   */
  const getPdfUrl = async (data) => {
    try {
      const response = await apiService.get(
        authEndpoits.paperView + "/" + data
      );
      setPdfUrl(response.data.data);
    } catch (error) {
      showToastErrorMessage(error.message);
    }
  };

  /**
   * api call to get user data
   */
  const getUserData = async () => {
    if (userName) {
      try {
        const responce = await apiService.get(authEndpoits.userMe, true);
        setUser(responce.data.data.user);
      } catch (error) {
        showToastErrorMessage(error.message);
      }
    }
  };

  /**
   * handle the checkbox change
   * @param {*} event
   */
  const handleCheckboxChange = (event) => {
    setCurrPage(1);
    setIsChecked(event.target.checked);

    if (!isChecked) {
      setUserId(user.uuid);
      setClickId("");
      setReplyParentId("");
    } else {
      setUserId("");
    }
  };

  // check if there is more page
  const hasMorePage = currPage < totalPage;

  /**
   * handle zoom in
   */
  const handleZoomIn = () => {
    if (scale < 2.5) {
      setScale((prevScale) => prevScale + 0.1);
    }
  };

  /**
   * handle zoom out
   */
  const handleZoomOut = () => {
    if (scale > 0.5) {
      setScale((prevScale) => prevScale - 0.1);
    }
  };

  /**
   * on pdf load successfully
   * @param {*} param0
   */
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  /**
   * handle chat input change
   * @param {*} event
   */
  const handleChatInputChange = (event) => {
    setChatInput(event.target.value);
  };

  /**
   * handle comment scroll for pagination
   */

  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;

      // Check if the container has scrollable content
      if (scrollHeight > clientHeight) {
        if (scrollTop + clientHeight === scrollHeight) {
          // Increment the current page by 1
          if (hasMorePage) {
            setCurrPage((prevPage) => prevPage + 1);

            // Load more data or perform any actions you need
            getPaperCommentData();
          }
        }
      }

      // Store the current scrollHeight for future comparison
      container.setAttribute("data-scroll-height", scrollHeight);
    }
  };

  /**
   * on click enter button
   * @param {*} event
   */
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleChatSubmit();
    }
  };

  /**
   * post api call comment
   */
  const handleChatSubmit = async () => {
    const obj = {
      paper_uuid: id,
      text: chatInput,
    };
    const parentid = {
      paper_uuid: id,
      text: chatInput,
      parent_uuid: replyPrentId,
    };
    try {
      // Send a POST request to the "/auth/sign_in" endpoint with the provided data
      if (replyPrentId) {
        const response = await apiService.post(
          authEndpoits.commet,
          parentid,
          true
        );
        if (response.status === "success") {
          getPaperCommentData();
          getReplyData(replyPrentId);
          setClickId("");
          setReplyParentId("");
        }
      } else {
        const response = await apiService.post(authEndpoits.commet, obj, true);
        if (response.status === "success") {
          getPaperCommentData();
        }
      }

      // Store the access token and refresh token in the local storage
    } catch (error) {
      showToastErrorMessage(error.message);
    }
    setChatInput("");
  };

  /**
   * handle emoji picker toggle
   */
  const handleEmojiButtonClick = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  /**
   * emoji picker
   * @param {*} emoji
   */
  const handleEmojiSelection = (emoji) => {
    if (!chatInput) {
      setChatInput(emoji.emoji);
    } else {
      setChatInput((prevChatInput) => prevChatInput + emoji.emoji);
    }
  };

  /**
   * handle reply toggle
   * @param {*} data
   */
  const handleToggleReply = async (data) => {
    if (clickId === data.uuid) {
      // If the clicked comment is already open, close it
      setClickId("");
      setReplyParentId("");
    } else {
      // If a different comment is clicked, open its reply div and fetch the data
      setClickId(data.uuid);
      setReplyParentId(data.uuid);
      getReplyData(data.uuid);
    }
  };

  /**
   * api call to get reply to comment of given uuid
   * @param {*} uuid
   */
  const getReplyData = async (uuid) => {
    const params = {
      // paper_uuid: "5a4d3d48-2577-4c56-88a0-ab91fef67ebe",
      paper_uuid: id,
      parent_uuid: uuid,
    };
    try {
      const response = await apiService.get(authEndpoits.commet, true, params);
      const comment = response.data.data;
      setReplyData(comment);
    } catch (error) {
      showToastErrorMessage(error.message);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <Fragment>
      <div className="pdf-view-page-container">
        <div className="pdf-view-page">
          <div className="pdf-view-page-content-container">
            <div className="pdf-view-page-content-header">
              <div className="pdf-view-header-left">
                <div>
                  <div className="pdf-view-header-text">
                    {paperData
                      ? "Board Papers of class " +
                        paperData.standard +
                        " " +
                        paperData.paper
                      : "Paper"}
                  </div>
                  <div>
                    <ul className="horizontal-list">
                      <li className="pdf-view-horizontal-list-text">
                        Year: {paperData ? paperData.year : ""}
                      </li>
                      <li className="pdf-view-horizontal-list-text">
                        Board: {paperData ? paperData.board : ""}
                      </li>
                      <li className="pdf-view-horizontal-list-text">
                        Comment: {metaData ? metaData.total_rows : ""}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="pdf-view-header-right">
                {/* <div className="pdf-view-icon">
                  <Icon name="download" />
                </div> */}
                {/* <div className="vertical-line"></div> */}
                {/* <div className="pdf-view-icon">
                  <Icon name="share" />
                </div> */}
              </div>
            </div>
            <div className="horizontal-line "></div>
            <div className="pdf-view-page-content-body">
              <div className="pdf-view-page-pdf-container">
                <div className="zoom-container">
                  <span>Zoom</span>
                  <div className="zoom-option-contianer" onClick={handleZoomIn}>
                    +
                  </div>
                  <div
                    className="zoom-option-contianer"
                    onClick={handleZoomOut}
                  >
                    -
                  </div>
                </div>
                <div className="all-page-container ">
                  <Document
                    file={pdfUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    renderAnnotationLayer={false}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page
                        scale={scale}
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                      />
                    ))}
                  </Document>
                </div>
              </div>
              <div className="pdf-view-page-comments-container">
                <div>
                  <span className="pdf-comments-header">
                    Comments ({metaData ? metaData.total_rows : ""})
                  </span>
                </div>
                <div className="pdf-comments-filter">
                  <FormControlLabel
                    className="pdf-comment-filter-checkbox"
                    control={
                      <Checkbox
                        onChange={
                          userName ? handleCheckboxChange : handleClickOpen
                        }
                      />
                    }
                    label="My Comments only "
                  />
                  <div className="pdf-view-filter-icon">
                    <Icon name="filterIcon" />
                  </div>
                </div>
                <div
                  className="comment-list-container"
                  onScroll={handleScroll}
                  ref={scrollContainerRef}
                >
                  <div>
                    {commentData.length !== 0 ? (
                      commentData.map((data, index) => {
                        return (
                          <div key={index}>
                            {data.parent_uuid === null && (
                              <div className="comment-item">
                                <div className="comment-container">
                                  <div className="profile-image">
                                    <img src={paperlogo} alt="profileimg" />
                                  </div>
                                  <div className="commnet-right-container">
                                    <div className="comment-profile">
                                      <div>
                                        <span className="profile-name">
                                          {data.first_name}
                                          {data.last_name}
                                        </span>
                                      </div>
                                      <div className="comment-vertical-line"></div>
                                      <div>
                                        <span className="profile-name gray">
                                          {getTimeAgo(data.created_at)}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="comment-text">
                                      <span>{data.text}</span>
                                    </div>

                                    <div className="mt-16">
                                      <TibButton
                                        type="contained"
                                        className="comment-reply link-button color-primary"
                                        onClick={() => handleToggleReply(data)}
                                        startIcon={<Icon name="replyIcon" />}
                                      >
                                        Replies ({data.reply_count})
                                      </TibButton>
                                    </div>
                                    {/* Replies */}
                                    {clickId === data.uuid && (
                                      <div className="comment-item">
                                        {replyData.map((reply) => {
                                          return (
                                            <div
                                              key={reply.uuid}
                                              className="comment-container"
                                            >
                                              <div className="profile-image">
                                                <img
                                                  src={paperlogo}
                                                  alt="profileimg"
                                                />
                                              </div>
                                              <div className="commnet-right-container">
                                                <div className="comment-profile">
                                                  <div>
                                                    <span className="profile-name">
                                                      {reply.first_name}
                                                      {reply.last_name}
                                                    </span>
                                                  </div>
                                                  <div className="comment-vertical-line"></div>
                                                  <div>
                                                    <span className="profile-name gray">
                                                      {getTimeAgo(
                                                        reply.created_at
                                                      )}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="comment-text">
                                                  <span>{reply.text}</span>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <div className="no-data-found-container">
                        No Data Found
                      </div>
                    )}
                  </div>
                </div>
                {replyPrentId && (
                  <div className="option-text">
                    Replying to comment...
                    <span
                      onClick={() => {
                        setReplyParentId("");
                        setClickId("");
                      }}
                    >
                      <Icon name="closeIcon" />
                    </span>
                  </div>
                )}
                <div className="chat-text-input">
                  {showEmojiPicker && (
                    <Popover
                      open={showEmojiPicker}
                      onClose={handleEmojiButtonClick}
                      anchorReference="anchorPosition"
                      anchorPosition={{ top: 350, left: 1700 }}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <div>
                        <EmojiPicker
                          onEmojiClick={handleEmojiSelection}
                          width={"100%"}
                        />
                      </div>
                    </Popover>
                  )}
                  <TextField
                    variant="outlined"
                    id="chat"
                    className="auth-input"
                    value={chatInput}
                    onChange={handleChatInputChange}
                    placeholder={
                      replyPrentId ? "Add a reply..." : "Add a comment..."
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            className="chat-text-btn"
                            onClick={handleEmojiButtonClick}
                          >
                            <Icon name="emojiIcon" />
                          </div>
                          <div className="chat-text-btn">
                            <button
                              onClick={
                                userName ? handleChatSubmit : handleClickOpen
                              }
                              className="chat-send-btn"
                              disabled={chatInput?.length ? false : true}
                            >
                              <Icon name="sendIcon" />
                            </button>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    onKeyDown={handleEnterKeyPress}
                  ></TextField>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginPopup
        open={open}
        onClose={handleClose}
        url={`/dashboard/pdf-view?id=${id}`}
      />
    </Fragment>
  );
};

export default PdfView;
