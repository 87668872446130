import { axiosInstance } from "../interceptores/api-interceptor";
import { baseUrl } from "./environment";

class ApiInterfaceServices {
  /**
   * Perform a POST request
   * @param {string} path - The request URL path
   * @param {object} body - The request body
   * @param {boolean} requireAuth - Determines if authentication is required
   * @param {object} params - The request parameters
   * @param {boolean} responseType - Determines the response type
   * @param {boolean} fullResponse - Determines if the full response is returned
   * @returns {object} The response data or the full response object
   */
  async post(
    path,
    body,
    requireAuth = false,
    params = {},
    responseType = false,
    fullResponse = false
  ) {
    const options = {
      params,
      headers: this.generateHeaders(requireAuth),
      responseType: responseType ? "arraybuffer" : "json",
    };

    try {
      const res = await axiosInstance.post(baseUrl + path, body, options);
      return fullResponse ? res : res.data;
    } catch (err) {
      throw err;
    }
  }

  /**
   * Perform a PUT request
   * @param {string} path - The request URL path
   * @param {object} body - The request body
   * @param {boolean} requireAuth - Determines if authentication is required
   * @param {object} params - The request parameters
   * @param {boolean} responseType - Determines the response type
   * @returns {object} The response object
   */
  put(path, body, requireAuth = false, params = {}, responseType = false) {
    const options = {
      params,
      headers: this.generateHeaders(requireAuth),
      responseType: "json",
    };

    return axiosInstance.put(baseUrl + path, body, options);
  }

  /**
   * Perform a PATCH request
   * @param {string} path - The request URL path
   * @param {object} body - The request body
   * @param {boolean} requireAuth - Determines if authentication is required
   * @param {object} params - The request parameters
   * @returns {object} The response object
   */
  patch(path, body, requireAuth = false, params = {}) {
    const options = {
      params,
      headers: this.generateHeaders(requireAuth),
    };

    return axiosInstance.patch(path, body, options);
  }

  /**
   * Perform a GET request
   * @param {string} path - The request URL path
   * @param {boolean} requireAuth - Determines if authentication is required
   * @param {object} params - The request parameters
   * @param {boolean} withCredentials - Determines if credentials are included
   * @param {boolean} responseType - Determines the response type
   * @returns {object} The response object
   */
  get(
    path,
    requireAuth = false,
    params = {},
    withCredentials,
    responseType = false
  ) {
    const options = {
      params,
      headers: this.generateHeaders(requireAuth),
      responseType: responseType ? "arraybuffer" : "json",
      withCredentials,
    };

    return axiosInstance.get(baseUrl + path, options);
  }

  /**
   * Perform a DELETE request
   * @param {string} path - The request URL path
   * @param {boolean} requireAuth - Determines if authentication is required
   * @param {object} params - The request parameters
   * @param {boolean} withCredentials - Determines if credentials are included
   * @returns {object} The response object
   */
  delete(path, requireAuth = false, params = {}, withCredentials) {
    const options = {
      params,
      headers: this.generateHeaders(requireAuth),
      withCredentials,
    };

    return axiosInstance.delete(path, options);
  }

  /**
   * Generate the request headers with an authorization token if required
   * @param {boolean} authHeaderRequired - Determines if the Authorization header is required
   * @returns {object} The generated headers object
   */
  generateHeaders(authHeaderRequired) {
    let headers = {};

    if (authHeaderRequired) {
      const unparsedToken = localStorage.getItem("access_token");

      if (unparsedToken) {
        headers["Authorization"] = `Bearer ${unparsedToken}`;
      }
    }

    return headers;
  }
}

export default ApiInterfaceServices;
