import { Navigate } from "react-router-dom";
import LoginPage from "../auth/login";
import SignupPage from "../auth/signup";
import ForgetPasswordPage from "../auth/forget-password";
import ResetPasswordPage from "../auth/reset-password";

export const AuthRoutes = [
  {
    path: "",
    element: <Navigate to="login" />,
  },
  { path: "login", element: <LoginPage /> },
  { path: "signup", element: <SignupPage /> },
  { path: "forget-password", element: <ForgetPasswordPage /> },
  { path: "reset-password", element: <ResetPasswordPage /> },
];
