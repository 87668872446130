import { AuthSideBar } from "../shared/components/auth-sidebar/auth-sidebar";
import ForgetPasswordForm from "../shared/components/auth/forget-password-form/forget-password-form";

export default function ForgetPasswordPage() {
  return (
    <div className="auth-container">
      <AuthSideBar title="Register for" />
      <ForgetPasswordForm />
    </div>
  );
}
