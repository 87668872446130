import { Outlet } from "react-router-dom";
import { Header } from "../../shared/components/header/header";
import {
  BreadCrumbProvider,
  UserProfileProvider,
  UserProvider,
} from "../../shared/services/context/subcontext";
import Breadcrumbs from "../../shared/components/bread-crumbs/breadcrumbs";

export default function Main() {
  return (
    <>
      <BreadCrumbProvider>
        <UserProvider>
          <UserProfileProvider>
            <Header />
            <Breadcrumbs />
            <Outlet />
          </UserProfileProvider>
        </UserProvider>
      </BreadCrumbProvider>
    </>
  );
}
