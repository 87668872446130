import { createTheme } from "@mui/material/styles";

const secondaryColor = "#00A6FB ";
const primaryColor = "#003554";
const textColor = "#000000";
const greyColor = "#797979";
const backgroundColor = "#F0F0F0";
const linkColor = "#6F8999";
const whiteColor = "#ffffff";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    text: {
      primary: textColor,
    },
    grey: {
      main: greyColor,
    },
    background: {
      default: backgroundColor,
    },
    link: {
      main: linkColor,
    },
    white: {
      main: whiteColor,
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: secondaryColor,
    },
    secondary: {
      main: primaryColor,
    },
    text: {
      primary: textColor,
    },
    grey: {
      main: greyColor,
    },
    background: {
      default: backgroundColor,
    },
    link: {
      main: linkColor,
    },
    white: {
      main: whiteColor,
    },
  },
});

export { lightTheme, darkTheme };
