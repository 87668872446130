import React from "react";
import { TibButton } from "../button/button";
import { useNavigate } from "react-router-dom";
import Error404 from "../../../assets/Error404.gif";
import "./page-not-found.scss";

const PageNotFound = () => {
  // useNavigate hook for navigation
  const navigate = useNavigate();

  /**
   * click handler
   * on click back to home page
   */
  const handleOnClick = () => {
    navigate("/");
  };
  return (
    <div className="page-not-found ">
      <div className="img">
        <img src={Error404} alt="404" loop="infinity" />
        <div className="btn">
          <TibButton className="primaryButton" onClick={handleOnClick}>
            Back to Home
          </TibButton>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
