import React, { Fragment, useContext, useEffect, useState } from "react";
import { CardContent, MenuItem, TextField } from "@mui/material";
import "./dashboard.scss";
import updates_img from "../../../assets/recent_updates.svg";
import { TibButton } from "../../../shared/components/button/button";
import { useForm } from "react-hook-form";
import ApiInterfaceServices from "../../../shared/services/api-interface";
import { authEndpoits } from "../../../utility/endpoints";
import {
  BreadCrumbContext,
  SearchFilterContext,
} from "../../../shared/services/context/subcontext";
import { useNavigate } from "react-router";
import { showToastErrorMessage } from "../../../shared/services/common-services";
import { getLocalStorageItem } from "../../../utility/utility";
import PdfIcon from "../../../assets/pdf.svg";
import Adsense from "../../../shared/components/ad-sense/ad-sense";
import {
  setLoggedinUser,
  setUserWithoutLogin,
} from "../../../utility/google-analytics";

/**
 *
 * @returns dashbaord page
 */
export default function Dashboard() {
  const BreadCrumbsData = [{ label: "Dashboard", url: "" }];

  // State hook to manage various data arrays
  const [stateData, setStateData] = useState({
    paperList: [],
    standardData: [],
    boardData: [],
    mediumData: [],
    updateData: [],
  });

  // Using a context hook to access search filter data
  const searchFilterContext = useContext(SearchFilterContext);

  // Using a context hook to access Breadcrumbs data
  const BreadCrumbs = useContext(BreadCrumbContext);

  // Creating an instance of the API service
  const apiService = new ApiInterfaceServices();

  // Custom hook for navigation
  const navigate = useNavigate();

  // Get User Name form local storage
  const userName = getLocalStorageItem("user");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // Set validation mode to onBlur
    mode: "onBlur",
  });

  // Validation rules for board selection
  const boardValidation = {
    validate: {
      notDefaultValue: (value) => value !== "Board" || "Please select a board",
    },
  };

  // Validation rules for standard selection
  const standardValidation = {
    validate: {
      notDefaultValue: (value) =>
        value !== "Standard" || "Please select a standard",
    },
  };

  // Validation rules for medium selection
  const mediumValidation = {
    validate: {
      notDefaultValue: (value) =>
        value !== "Medium" || "Please select a medium",
    },
  };

  // Register form data and validation rules
  const registerData = {
    boardRegister: register("board", boardValidation),
    standardRegister: register("standard", standardValidation),
    mediumRegister: register("medium", mediumValidation),
  };

  useEffect(() => {
    Promise.all([
      getSubjectData(),
      getStandardData(),
      getBoardData(),
      getMediumData(),
      getUpdatesData(),
      searchFilterContext.setSearchFilter(""),
    ]);
    BreadCrumbs.setBreadCrumb(BreadCrumbsData);
  }, []);

  // Handle form submission
  /**
   * Submits the form data, sets the search filter in the context, and navigates to the question paper list page.
   * @param {object} data - The form data to be submitted
   */
  const onSubmit = (data) => {
    searchFilterContext.setSearchFilter(data);
    navigate("/dashboard/questionpaper-list");
  };

  // Get subject data
  /**
   * Fetches subject data from the server and updates the state.
   * Shows an error toast message if there's an error.
   */
  const getSubjectData = async () => {
    try {
      const response = await apiService.get(authEndpoits.randomPaper);
      const subjects = response.data.data;

      // Update subjectList in stateData object using setStateData
      setStateData((prevState) => ({
        ...prevState,
        paperList: subjects,
      }));
    } catch (error) {
      // eslint-disable-next-line no-lone-blocks
      {
        error.response
          ? showToastErrorMessage(error.response.data.message)
          : showToastErrorMessage(error.message);
      }
    }
  };

  // Get standard data
  /**
   * Fetches standard data from the server and updates the state.
   * Shows an error toast message if there's an error.
   */
  const getStandardData = async () => {
    try {
      const response = await apiService.get(authEndpoits.standard);
      const standard = response.data.data;

      // Update standardData in stateData object using setStateData
      setStateData((prevState) => ({
        ...prevState,
        standardData: standard,
      }));
    } catch (error) {
      // eslint-disable-next-line no-lone-blocks
      {
        error.response
          ? showToastErrorMessage(error.response.data.message)
          : showToastErrorMessage(error.message);
      }
    }
  };

  // Get board data
  /**
   * Fetches board data from the server and updates the state.
   * Shows an error toast message if there's an error.
   */
  const getBoardData = async () => {
    try {
      const response = await apiService.get(authEndpoits.board, true);
      const board = response.data.data;

      // Update boardData in stateData object using setStateData
      setStateData((prevState) => ({
        ...prevState,
        boardData: board,
      }));
    } catch (error) {
      // eslint-disable-next-line no-lone-blocks
      {
        error.response
          ? showToastErrorMessage(error.response.data.message)
          : showToastErrorMessage(error.message);
      }
    }
  };

  // Get medium data
  /**
   * Fetches medium data from the server and updates the state.
   * Shows an error toast message if there's an error.
   */
  const getMediumData = async () => {
    try {
      const response = await apiService.get(authEndpoits.medium);
      const medium = response.data.data;

      // Update mediumData in stateData object using setStateData
      setStateData((prevState) => ({
        ...prevState,
        mediumData: medium,
      }));
    } catch (error) {
      // eslint-disable-next-line no-lone-blocks
      {
        error.response
          ? showToastErrorMessage(error.response.data.message)
          : showToastErrorMessage(error.message);
      }
    }
  };

  // Get updates data
  /**
   * Fetches updates data from the server and updates the state.
   * Shows an error toast message if there's an error.
   */
  const getUpdatesData = async () => {
    try {
      const response = await apiService.get(authEndpoits.updates);
      const update = response.data.data;

      // Update updateData in stateData object using setStateData
      setStateData((prevState) => ({
        ...prevState,
        updateData: update,
      }));
    } catch (error) {
      // eslint-disable-next-line no-lone-blocks
      {
        error.response
          ? showToastErrorMessage(error.response.data.message)
          : showToastErrorMessage(error.message);
      }
    }
  };

  /**
   * on click handle submit
   */
  const handleFormSubmit = () => {
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    getLocalStorageItem("access_token")
      ? setLoggedinUser(userName)
      : setUserWithoutLogin();
  }, []);

  return (
    <Fragment>
      <div className="dashboard-container">
        <div className="dashboard">
          <div>
            <span className="dashboard-text-name">
              Hello {userName ? userName : "Genius..!!"}
            </span>
            <p className="dashboard-text-subtext">
              Which Question bank do you want?
            </p>
          </div>
          <div>
            <div className="subject-card-heading">
              <div className=" left">Recent</div>
              <div
                className="right"
                onClick={() => navigate("/dashboard/questionpaper-list")}
              >
                View More
              </div>
            </div>
            <div className="dashboard-subject-card-container">
              {/* Map through subjectList */}
              {stateData.paperList.length !== 0 ? (
                stateData.paperList.map((sub, index) => {
                  return (
                    <div
                      className="dasboard-subject-card"
                      key={index}
                      onClick={async () => {
                        navigate(`/dashboard/pdf-view?id=${sub.paper_uuid}`);
                      }}
                    >
                      <CardContent className="card-content">
                        <div className="dashboard-subjext-card-details-container">
                          <div className="dasboard-subject-card-image-container">
                            <img height="28" width="28" src={PdfIcon} alt="" />
                          </div>
                          <div className="subject-name-text-container">
                            <div>
                              <div className="subject-name-text">
                                {sub.paper_name}
                              </div>
                              <div>
                                <ul className="horizontal-list dashboard-card">
                                  <li> {sub.board}</li>
                                  <li> {sub.standard}</li>
                                  <li> {sub.year}</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </div>
                  );
                })
              ) : (
                <div className="no-data-found-container">No Data Found</div>
              )}
            </div>
          </div>

          {/* <div className="dashboard-advertisement-container"> */}
          <Adsense
            style={{ display: "flex", height: "90px " }}
            slot="1234567890"
          />
          {/* </div> */}
          <div className="dashboard-recent-container">
            <div className="dashboard-recent-container-left">
              <div className="dashboard-resent-header-text">
                Recent Question Bank
              </div>
              <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="dual-input-container mt-16">
                    {/* Select Standard field */}
                    <div className=" auth-input-dual bg-padding ">
                      <TextField
                        select
                        variant="outlined"
                        id="standard"
                        defaultValue="Standard"
                        error={errors.standard ? true : false}
                        {...registerData.standardRegister}
                        className="auth-input custom-select"
                      >
                        <MenuItem className="grey" value="Standard" disabled>
                          Standard
                        </MenuItem>
                        {stateData.standardData &&
                          stateData.standardData.map((standard) => {
                            return (
                              <MenuItem
                                key={standard.uuid}
                                value={standard.name}
                              >
                                {standard.name}
                              </MenuItem>
                            );
                          })}
                      </TextField>

                      {errors.standard && (
                        <div>
                          <div className="error-message">
                            {errors.standard.message}
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Select medium field */}
                    <div className="auth-input-dual bg-padding ">
                      <TextField
                        select
                        variant="outlined"
                        id="medium"
                        className="auth-input custom-select"
                        error={errors.medium ? true : false}
                        defaultValue="Medium"
                        {...registerData.mediumRegister}
                      >
                        <MenuItem value="Medium" disabled>
                          Medium
                        </MenuItem>
                        {stateData.mediumData &&
                          stateData.mediumData.map((medium) => {
                            return (
                              <MenuItem key={medium.uuid} value={medium.name}>
                                {medium.name}
                              </MenuItem>
                            );
                          })}
                        {/* Add more options as needed */}
                      </TextField>
                      {errors.medium && (
                        <div>
                          <div className="error-message">
                            {errors.medium.message}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-16 bg-padding ">
                    <TextField
                      select
                      variant="outlined"
                      id="board"
                      className="auth-input custom-select"
                      error={errors.board ? true : false}
                      defaultValue="Board"
                      {...registerData.boardRegister}
                    >
                      <MenuItem value="Board" disabled>
                        Board
                      </MenuItem>

                      {stateData.boardData &&
                        stateData.boardData.map((board) => {
                          return (
                            <MenuItem key={board.uuid} value={board.name}>
                              {board.name}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                    {errors.board && (
                      <div className="error-message">
                        {errors.board.message}
                      </div>
                    )}
                  </div>
                  <div className="dashboard-recent-qb-download-button">
                    <TibButton
                      className="primaryButton "
                      // Trigger form submission
                      onClick={handleFormSubmit}
                    >
                      Search
                    </TibButton>
                  </div>
                </form>
                {/* <div className="dashbaord-left-advertisement-container"> */}
                <Adsense
                  style={{ display: "flex", height: "48px " }}
                  slot="1234567891"
                />
                {/* </div> */}
              </div>
            </div>
            <div className="vertical-line"></div>
            <div className="dashboard-recent-container-right">
              <span className="dashboard-resent-header-text">
                Recent Updates/New Features
              </span>
              <div className="dashboard-recent-update-list">
                {stateData.updateData.length !== 0 ? (
                  stateData.updateData.map((updates) => {
                    return (
                      <div
                        className="dashboard-recent-update-container"
                        key={updates.uuid}
                      >
                        <img src={updates_img} alt="update" />
                        <div className="dashboard-recent-update-text-container">
                          <div className="dasbaord-updates-heading">
                            {updates.title}
                          </div>
                          <div className="dasbaord-updates-subtext">
                            {updates.description}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="no-data-found-container">No Data Found</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
