import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TextField, InputAdornment } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import googleIcon from "../../../../assets/google.png";
import { TibButton } from "../../button/button";
import Icon from "../../icon/icon";
import "./signup-form.scss";
import {
  emailValidation,
  passwordValidation,
} from "../../../validations/validation";
import ApiInterfaceServices from "../../../services/api-interface";
import { authEndpoits } from "../../../../utility/endpoints";
import { setLocalStorageItem } from "../../../../utility/utility";
import { LoginSocialGoogle } from "reactjs-social-login";
import { showToastErrorMessage } from "../../../services/common-services";

const SignupForm = () => {
  // State to toggle password visibility
  const [showPassword, setShowPassword] = useState(false);
  // State to toggle confirm password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // State variable to hold the access token
  const [accessToken, setAccessToken] = useState("");
  // State variable to hold the provider ID
  const [providerId, setProvideID] = useState("");
  //usenevigate hook form react router dom to nevigate
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onBlur", // Set validation mode to onBlur
  });

  const apiService = new ApiInterfaceServices();

  /**
   * This useEffect hook is used to handle social login functionality.
   * It will trigger when the dependencies (providerId or accessToken) change.
   */
  useEffect(() => {
    if (providerId && accessToken) {
      try {
        let Obj = {
          access_token: accessToken,
          provider_id: providerId,
        };
        // Send a POST request to the "/auth/sign_up" endpoint with the provided data
        const res = apiService
          .post(authEndpoits.socialLogin, Obj, true)
          .then((res) => {
            // Store the access token and refresh token in the local storage
            setLocalStorageItem("access_token", res.data.access_token);
            setLocalStorageItem("refresh_token", res.data.refresh_token);
            // Navigate to the "/dashbord" route
            navigate("/dashboard");
          });
      } catch (error) {
        showToastErrorMessage(error.response.data.message);
      }
    }
  });

  /**
   * Handle form submission
   * @param {object} data - The form data to be submitted
   */
  const onSubmit = async (data) => {
    const { firstName, lastName, email, password, confirmPassowrd } = data;

    try {
      // Send a POST request to the "/auth/sign_up" endpoint with the provided data
      const response = await apiService.post(
        authEndpoits.signup,
        {
          first_name: firstName,
          last_name: lastName,
          email,
          password,
          confirm_password: confirmPassowrd,
        },
        true
      );

      // Store the access token and refresh token in the local storage
      setLocalStorageItem("access_token", response.data.access_token);
      setLocalStorageItem("refresh_token", response.data.refresh_token);
      // Navigate to the "/auth/personal-detail" route
      navigate("/dashboard");
    } catch (error) {
      // eslint-disable-next-line no-lone-blocks
      {
        error.response
          ? showToastErrorMessage(error.response.data.message)
          : showToastErrorMessage(error.message);
      }
    }
  };

  /**
   * Toggles the visibility of the password field.
   */
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  /**
   * Toggles the visibility of the confirm password field.
   */
  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Validation rules for first name field
  const firstNameValidation = {
    required: "Please enter a first name",
  };
  // Validation rules for last name field
  const lastNameValidation = {
    required: "Please enter a last name",
  };

  // Validation rules for confirm password field
  const confirmPasswordValidation = {
    required: "Confirm password is required",
    validate: (value) => {
      const password = watch("password");
      return value === password || "Passwords do not match";
    },
  };

  /**
   * Returns the appropriate error message based on the error type.
   * @param {object} error - The error object from react-hook-form.
   * @returns {string} - The error message.
   */
  const getErrorMessage = (error) => {
    if (error.type === "required") {
      return "Password is required";
    }
    if (error.type === "minLength") {
      return "Password should have at least 8 characters";
    }
    if (error.type === "alphanumeric") {
      return error.message;
    }
    return "Invalid password";
  };

  //Json for the input field register
  const registerData = {
    // Property: firstNameRegister
    // Description: This property represents the email registration field and validates the firstName using firstNameValidation function.
    firstNameRegister: register("firstName", firstNameValidation),
    // Property: lastNameRegister
    // Description: This property represents the email registration field and validates the lastName using lastNameValidation function.
    lastNameRegister: register("lastName", lastNameValidation),
    // Property: emailRegister
    // Description: This property represents the email registration field and validates the email using emailValidation function.
    emailRegister: register("email", emailValidation),

    // Property: passwordRegister
    // Description: This property represents the password registration field and validates the password using passwordValidation function.
    passwordRegister: register("password", passwordValidation),

    // Property: confirmPasswordRegister
    // Description: This property represents the confirmation password registration field and validates the confirmation password using confirmPasswordValidation function.
    confirmPasswordRegister: register(
      "confirmPassowrd",
      confirmPasswordValidation
    ),
  };

  /**
   * Handles the form submission.
   *
   */
  const handleSignup = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <div className="width-50 gradient">
      <div className="auth-input-container">
        <div className="header primary-color">
          <span>Your journey starts here</span>
          <br />
          <span>Sign up now!!</span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Full Name field */}
          <div className="auth-dual-input-container mt-28">
            <div className="auth-input-dual">
              <TextField
                InputProps={{
                  className: "textField",
                }}
                variant="outlined"
                id="firstName"
                placeholder="First Name"
                className="auth-input "
                error={errors.firstName ? true : false}
                {...registerData.firstNameRegister}
              />
              {errors.firstName && (
                <div>
                  <div className="error-message">
                    {errors.firstName.message}
                  </div>
                </div>
              )}
            </div>
            <div className="auth-input-dual">
              <TextField
                InputProps={{
                  className: "textField",
                }}
                variant="outlined"
                id="lastName"
                placeholder="Last Name"
                className="auth-input "
                error={errors.lastName ? true : false}
                {...registerData.lastNameRegister}
              />

              {errors.lastName && (
                <div>
                  <div className="error-message">{errors.lastName.message}</div>
                </div>
              )}
            </div>
          </div>
          {/* Email field */}
          <div className="m-20">
            <TextField
              InputProps={{
                className: "textField",
              }}
              variant="outlined"
              id="email"
              placeholder="Email"
              className="auth-input "
              error={errors.email ? true : false}
              {...registerData.emailRegister}
            />
            {errors.email && (
              <div className="error-message">{errors.email.message}</div>
            )}
          </div>

          {/* Password field */}
          <div className="m-20">
            <TextField
              InputProps={{
                className: "textField",
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={handleTogglePassword}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon
                      name={showPassword ? "passwordEyeHide" : "passwordEye"}
                      color="black"
                    />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              className="auth-input "
              error={errors.password ? true : false}
              type={showPassword ? "text" : "password"}
              id="password"
              placeholder="Password"
              {...registerData.passwordRegister}
            />

            {errors.password && (
              <div className="error-message">
                {getErrorMessage(errors.password)}
              </div>
            )}
          </div>

          {/* Confirm Password field */}
          <div className="m-20">
            <TextField
              InputProps={{
                className: "textField",
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={handleToggleConfirmPassword}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon
                      name={
                        showConfirmPassword ? "passwordEyeHide" : "passwordEye"
                      }
                      color="black"
                    />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              className="auth-input"
              error={errors.confirmPassowrd ? true : false}
              type={showConfirmPassword ? "text" : "password"}
              id="confirmPassowrd"
              placeholder="Confirm Password"
              {...registerData.confirmPasswordRegister}
            />

            {errors.confirmPassowrd && (
              <div className="error-message">
                {errors.confirmPassowrd.message}
              </div>
            )}
          </div>

          {/* Signup button */}
          <div className="m-20">
            <TibButton
              className="primaryButton"
              // Trigger form submission
              onClick={handleSignup}
            >
              Sign up
            </TibButton>
          </div>
        </form>

        {/* Other elements */}
        <div>
          {/* OR separator */}
          <div className="text-center">
            <p className="line ">
              <span>OR</span>
            </p>
          </div>

          {/* Social login buttons */}
          <div className="auth-social-container">
            {/* Google */}
            <div className="auth-link-button">
              <LoginSocialGoogle
                client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                onResolve={({ provider, data }) => {
                  setAccessToken(data.access_token);
                  setProvideID(provider + ".com");
                }}
              >
                <TibButton className="secondoryButton" buttontype="outlined">
                  <img src={googleIcon} alt="Google" />
                </TibButton>
              </LoginSocialGoogle>
            </div>
          </div>

          {/* Sign in link */}
          <div className="m-20 text-center">
            <NavLink to="/auth/login" className="navLink">
              Already have an account?
              <span className="navLink-text-bold"> Sign in</span>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
