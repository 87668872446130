import React, { useState } from "react";
import { showToastErrorMessage } from "../services/common-services";
import axios from "axios";

const { useCallback, useMemo, useEffect } = React;

const axiosInstance = axios.create();

const useAxiosLoader = () => {
  const [counter, setCounter] = useState(0);
  // State to track if a redirect should occur
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const inc = useCallback(
    () => setCounter((counter) => counter + 1),
    [setCounter]
  );
  const dec = useCallback(
    () => setCounter((counter) => counter - 1),
    [setCounter]
  );

  const interceptors = useMemo(
    () => ({
      /**
       * Intercept the request before it is sent
       * @param {object} config - The request config
       * @returns {object} The modified request config
       */
      request: (config) => {
        inc();
        return config;
      },
      /**
       * Intercept the response before it is used
       * @param {object} response - The response object
       * @returns {object} The original response object
       */
      response: (response) => {
        dec();
        return response;
      },
      /**
       * Handle  errors
       * @param {object} error - The response error
       * @returns {Promise} A rejected promise with the error
       */
      error: (error) => {
        dec();
        const { response } = error;

        if (response) {
          const message = response.data.message;
          switch (response.status) {
            case 400:
              // Handle 400 error
              break;
            case 401:
              // Handle 401 error
              // Set shouldRedirect to true to trigger a redirect
              setShouldRedirect(true);
              break;
            case 402:
              // Reauthenticate User
              // Add code here to handle reauthentication
              break;
            case 403:
              // Access Denied
              showToastErrorMessage(message);
              break;
            case 404:
              // Handle 404 error
              showToastErrorMessage(message);
              break;
            case 500:
              // Handle 500 error
              showToastErrorMessage(message);
              break;
            default:
              // Handle other errors
              showToastErrorMessage(message);
              break;
          }
        }
        return Promise.reject(error);
      },
    }),
    [inc, dec]
  );

  useEffect(() => {
    // Add request and response interceptors to axiosInstance
    axiosInstance.interceptors.request.use(
      interceptors.request,
      interceptors.error
    );
    axiosInstance.interceptors.response.use(
      interceptors.response,
      interceptors.error
    );
  }, [interceptors]);

  // Check if shouldRedirect is true and stop the loading state if a redirect is required
  useEffect(() => {
    if (shouldRedirect) {
      setCounter(0);
      setShouldRedirect(false);
    }
  }, [shouldRedirect]);

  return [counter > 0];
};

export { axiosInstance, useAxiosLoader };
