import { AuthSideBar } from "../shared/components/auth-sidebar/auth-sidebar";
import LoginForm from "../shared/components/auth/login-form/login-form";

export default function LoginPage() {
  return (
    <div className="auth-container">
      <AuthSideBar isSignIn={"ture"} />
      <LoginForm />
    </div>
  );
}
