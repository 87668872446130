import "./header.scss";
import logo from "../../../assets/dashboard-logo.svg";
import avatar from "../../../assets/profile-icon.svg";
import { useEffect, useState, useRef, useContext } from "react";
import ApiInterfaceServices from "../../services/api-interface";
import { authEndpoits } from "../../../utility/endpoints";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../../utility/utility";
import { useNavigate } from "react-router-dom";
import { TibButton } from "../button/button";
import {
  UserContext,
  UserProfileContext,
} from "../../services/context/subcontext";
import { LinearProgress } from "@mui/material";
import { useAxiosLoader } from "../../interceptores/api-interceptor";
import { showToastErrorMessage } from "../../services/common-services";
import { cloudFrontUrl } from "../../services/environment";
import Icon from "../icon/icon";

/**
 *
 * @returns header component
 */
export const Header = () => {
  // State hook to manage the visibility of the dropdown
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  //State hook to manage User Data
  const [userProfileData, setUserProfileData] = useState();

  // Ref hook to reference the dropdown element
  const dropdownRef = useRef(null);

  // Creating an instance of the API service
  const apiService = new ApiInterfaceServices();

  // Custom hook for navigation
  const navigate = useNavigate();

  // Using a context hook to access user data
  const userContext = useContext(UserContext);
  const userData = userContext.user;

  // Using a context hook to access user data
  const userProfileContext = useContext(UserProfileContext);
  const userProfilePicture = userProfileContext.userProfile;

  // Custom hook to track loading state when making API requests
  const [isloading] = useAxiosLoader();

  useEffect(() => {
    getUserData();

    // Add event listener to handle click outside dropdown
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getUserData();
  }, [userProfilePicture]);

  /**
   * api call to get user data
   */
  const getUserData = async () => {
    if (getLocalStorageItem("access_token")) {
      try {
        const response = await apiService.get(authEndpoits.userMe, true);
        setUserProfileData(response.data.data.user);
        const user = getLocalStorageItem("user");

        const userName =
          response.data.data.user.first_name +
          " " +
          response.data.data.user.last_name;

        if (user === null) {
          setLocalStorageItem("user", userName);
        }
      } catch (error) {
        showToastErrorMessage(error.message);
      }
    }
  };

  /**
   * handles logout
   */
  const handleLogout = () => {
    localStorage.clear();
    navigate("/auth");
  };

  /**
   * handles on click edit profile
   */
  const handleEditProfile = () => {
    navigate(`/dashboard/edit-profile`);
    setIsDropdownOpen(false);
  };

  /**
   * handles dropdown toggle
   */
  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  /**
   * handles click outside dropdown
   */
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  // const ProfileAvatar = `${CLOUDFRONT_URL}+${userProfileData.profile_url}`;
  return (
    <div>
      <div className="dashboard-header-container">
        <div>
          <a href="/dashboard">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div className="dashboard-header-left-container">
          {!userProfileData && (
            <div onClick={() => navigate("/auth")}>
              <TibButton startIcon={<Icon name="loginIcon" />}>Login</TibButton>
            </div>
          )}
          {userProfileData && (
            <div className="profile-container" onClick={toggleDropdown}>
              <img
                className="image-container"
                src={
                  userProfilePicture !== undefined
                    ? userProfilePicture
                    : userProfileData.profile_url !== null
                    ? cloudFrontUrl + userProfileData.profile_url
                    : avatar
                }
                alt=""
              />
              <span className="profile-name-container">
                {userData
                  ? userData.first_name + " " + userData.last_name
                  : getLocalStorageItem("user")}
              </span>
            </div>
          )}
          {isDropdownOpen && (
            <div className="dropdown" ref={dropdownRef}>
              <div className="header-dropdown-profile-contianre">
                <div className="avatar-container">
                  <div className="avatar-div">
                    <img
                      src={
                        userProfilePicture !== undefined
                          ? userProfilePicture
                          : userProfileData.profile_url !== null
                          ? cloudFrontUrl + userProfileData.profile_url
                          : avatar
                      }
                      alt=""
                      className="profile-avatar"
                    />
                  </div>
                  <div className="profile-name-container">
                    {getLocalStorageItem("user")}
                  </div>
                </div>
              </div>
              <div className="header-dropdown-btn-container">
                <div className="filter-button">
                  <TibButton
                    className="primaryButton"
                    onClick={handleEditProfile}
                    // Trigger form submission
                  >
                    Edit Profile
                  </TibButton>
                </div>
                <div className="filter-button">
                  <TibButton
                    className="secondoryButton"
                    buttontype="outlined"
                    onClick={handleLogout}
                  >
                    Logout
                  </TibButton>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="loading-contaner">
        {isloading && <LinearProgress color="inherit" />}
      </div>
    </div>
  );
};
