import { createContext, useState } from "react";

// Create SearchFilter context
export const SearchFilterContext = createContext();

// SearchFilter provider component
export const SearchFilterProvider = ({ children }) => {
  const [searchFilter, setSearchFilter] = useState({});

  return (
    // Provide the searchFilter and setSearchFilter values to the consuming components
    <SearchFilterContext.Provider value={{ searchFilter, setSearchFilter }}>
      {children}
    </SearchFilterContext.Provider>
  );
};

// Create user context
export const UserContext = createContext();

// SearchFilter provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();

  return (
    // Provide the searchFilter and setSearchFilter values to the consuming components
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Create userProfilePicture context
export const UserProfileContext = createContext();

// SearchFilter provider component
export const UserProfileProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState();

  return (
    // Provide the searchFilter and setSearchFilter values to the consuming components
    <UserProfileContext.Provider value={{ userProfile, setUserProfile }}>
      {children}
    </UserProfileContext.Provider>
  );
};

// Create SignupData context
export const SignupDataContext = createContext();

// SearchFilter provider component
export const SignupDataProvider = ({ children }) => {
  const [signupData, setSignupData] = useState({});

  return (
    // Provide the searchFilter and setSearchFilter values to the consuming components
    <SignupDataContext.Provider value={{ signupData, setSignupData }}>
      {children}
    </SignupDataContext.Provider>
  );
};

// Create Breadcrumb context
export const BreadCrumbContext = createContext();

// Breadcrumb provider component
export const BreadCrumbProvider = ({ children }) => {
  const [breadCrumb, setBreadCrumb] = useState();

  return (
    // Provide the Breadcrumb and setBreadcrumb values to the consuming components
    <BreadCrumbContext.Provider value={{ breadCrumb, setBreadCrumb }}>
      {children}
    </BreadCrumbContext.Provider>
  );
};
