// Validation rules for password field
export const passwordValidation = {
  required: "Password is required",
  minLength: {
    value: 8,
    message: "Password should have at least 8 characters",
  },
  validate: {
    alphanumeric: (value) =>
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/.test(value) ||
      "Password should contain atleast one uppercase lowercase numeric value and special charecter",
  },
};

// Validation rules for email field
export const emailValidation = {
  required: "Email is required",
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: "Invalid email address",
  },
};


