import { Button } from "@mui/material";
// import Button from "@mui/material-next/Button";

// Defining the TibButton component
export const TibButton = ({
  // Content within the button component
  children,
  // Event handler for button click
  onClick,
  // Variant of the button (default is "contained")
  buttontype = "contained",
  // Icon displayed before the button's text
  startIcon,
  // Icon displayed after the button's text
  endIcon,
  // CSS class name for additional styling
  className,
  // Boolean indicating if the button is disabled (default is false)
  disabled = false,
}) => {
  // Creating the CSS class name for the button, adding "disableButton" class if disabled
  const buttonClassName = disabled ? `${className} disableButton` : className;

  // Rendering the Button component with provided props and custom CSS class
  return (
    <Button
      id="tib-button"
      onClick={onClick}
      variant={buttontype}
      startIcon={startIcon}
      endIcon={endIcon}
      className={buttonClassName}
      disabled={disabled}
      disableRipple
      disableFocusRipple
    >
      {children}
    </Button>
  );
};
