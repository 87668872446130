import React, { Fragment, useContext, useEffect, useState } from "react";
import "./edit-profile.scss";
import { MenuItem, TextField } from "@mui/material";
import { authEndpoits } from "../../../utility/endpoints";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import ApiInterfaceServices from "../../../shared/services/api-interface";
import { showToastErrorMessage } from "../../../shared/services/common-services";
import Icon from "../../../shared/components/icon/icon";
import { TibButton } from "../../../shared/components/button/button";
import iamgeVector from "../../../assets/profile-icon.svg";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../../utility/utility";
import {
  BreadCrumbContext,
  UserContext,
  UserProfileContext,
} from "../../../shared/services/context/subcontext";

import { useAxiosLoader } from "../../../shared/interceptores/api-interceptor";
import { cloudFrontUrl } from "../../../shared/services/environment";

/**
 *
 * @returns edit profile page
 */
const EditProfilePage = () => {
  const BreadCrumbsData = [
    { label: "Dashboard", url: "dashboard" },
    { label: "Edit Profile", url: "" },
  ];

  // Using a context hook to access Breadcrumbs data
  const BreadCrumbs = useContext(BreadCrumbContext);

  // State hook to manage selected values for different dropdowns
  const [dropdownValues, setDropdownValues] = useState({
    countryValue: [],
    mediumValue: [],
    boardValue: [],
    standardValue: [],
  });
  //State hook to store user
  const [userData, setUserData] = useState();

  // State hook to store user profile data
  const [userProfileData, setUserProfileData] = useState();

  // State hook to manage the image
  const [image, setImage] = useState(null);

  //state hook for image file
  const [imageFile, setImageFile] = useState();

  // Using a context hook to access user data
  const userContext = useContext(UserContext);

  // Using a context hook to access user data
  const userProfileContext = useContext(UserProfileContext);

  // Custom hook for navigation
  const navigate = useNavigate();

  // Custom hook to track loading state when making API requests
  const [isloading] = useAxiosLoader();

  // Creating an instance of the API service
  const apiService = new ApiInterfaceServices();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // Set validation mode to onBlur
    mode: "onBlur",
  });

  // Validation rules for board selection
  const boardValidation = {
    validate: {
      notDefaultValue: (value) => value !== "Board" || "Please select a board",
    },
  };

  // Validation rules for standard selection
  const standardValidation = {
    validate: {
      notDefaultValue: (value) =>
        value !== "Standard" || "Please select a standard",
    },
  };

  // Validation rules for medium selection
  const mediumValidation = {
    validate: {
      notDefaultValue: (value) =>
        value !== "Medium" || "Please select a medium",
    },
  };

  // Validation rules for first name field
  const firstNameValidation = {
    required: "Please enter a first name",
  };
  // Validation rules for last name field
  const lastNameValidation = {
    required: "Please enter a last name",
  };
  // Validation rules for select country code field
  const countryCodeValidation = {
    validate: {
      notDefaultValue: (value) =>
        value !== "Country code" || "Please select a country code",
    },
  };

  // Validation rules for mobile number field
  const mobileValidation = {
    required: "Please enter a mobile number",
    pattern: {
      value: /^\d{10}$/, // Assuming a 10-digit mobile number
      message: "Please enter a valid mobile number",
    },
  };

  // Validation rules for select gender field
  const genderValidation = {
    validate: {
      notDefaultValue: (value) =>
        value !== "Gender" || "Please select a gender",
    },
  };

  // Json for the input field register
  const registerData = {
    firstNameRegister: register("firstName", firstNameValidation),
    lastNameRegister: register("lastName", lastNameValidation),
    genderRegister: register("gender", genderValidation),
    boardRegister: register("board", boardValidation),
    mobileRegister: register("mobileNumber", mobileValidation),
    standardRegister: register("standard", standardValidation),
    mediumRegister: register("medium", mediumValidation),
    countryCodeRegister: register("countryCode", countryCodeValidation),
  };

  useEffect(() => {
    BreadCrumbs.setBreadCrumb(BreadCrumbsData);
    Promise.all([
      getCountryData(),
      getMediumData(),
      getStandardData(),
      getBoardData(),
      getUserData(),
    ]);
  }, []);

  /**
   * fetch user data
   */
  const getUserData = async () => {
    try {
      const response = await apiService.get(authEndpoits.userMe, true);
      setUserData(response.data.data.user);
      const user = getLocalStorageItem("user");
      const userName =
        response.data.data.user.first_name +
        " " +
        response.data.data.user.last_name;

      if (user === null) {
        setLocalStorageItem("user", userName);
        userContext.setUser(response.data.data.user);
      }
      getUserProfileData(response.data.data.user);
    } catch (error) {
      showToastErrorMessage(error.response.data.message);
    }
  };

  /**
   * Fetches user profile data
   */
  const getUserProfileData = async (user) => {
    try {
      const response = await apiService.get(
        authEndpoits.userProfileData + "/" + user.uuid,
        true
      );
      const userProfile = response.data.data;
      setUserProfileData(userProfile);
    } catch (error) {
      showToastErrorMessage(error.message);
    }
  };

  /**
   * Fetches country data from the API
   */
  const getCountryData = async () => {
    try {
      const response = await apiService.get(authEndpoits.countryCode, true);
      const country = response.data.data;
      setDropdownValues((prevState) => ({
        ...prevState,
        countryValue: country,
      }));
    } catch (error) {
      showToastErrorMessage(error.message);
    }
  };

  /**
   * Fetches medium data from the API
   */
  const getMediumData = async () => {
    try {
      const response = await apiService.get(authEndpoits.medium, true);
      const medium = response.data.data;
      setDropdownValues((prevState) => ({
        ...prevState,
        mediumValue: medium,
      }));
    } catch (error) {
      showToastErrorMessage(error.message);
    }
  };

  /**
   * Fetches standard data from the API
   */
  const getStandardData = async () => {
    try {
      const response = await apiService.get(authEndpoits.standard, true);
      const standard = response.data.data;
      setDropdownValues((prevState) => ({
        ...prevState,
        standardValue: standard,
      }));
    } catch (error) {
      showToastErrorMessage(error.message);
    }
  };

  /**
   * Fetches board data from the API
   */
  const getBoardData = async () => {
    try {
      const response = await apiService.get(authEndpoits.board, true);
      const board = response.data.data;
      setDropdownValues((prevState) => ({
        ...prevState,
        boardValue: board,
      }));
    } catch (error) {
      showToastErrorMessage(error.message);
    }
  };

  /**
   *
   * api call
   */
  const onSubmit = async (data) => {
    const {
      firstName,
      lastName,
      mobileNumber,
      medium,
      standard,
      countryCode,
      board,
      gender,
    } = data;
    const obj = {
      first_name: firstName,
      last_name: lastName,
      mobile_no: mobileNumber,
      medium_uuid: medium,
      standard_uuid: standard,
      country_code: countryCode,
      board_uuid: board,
      gender,
    };
    try {
      const response = await apiService.put(
        authEndpoits.userProfile,
        obj,
        true
      );

      getUserData();
      updateUserProfilePicture();
      navigate("/dashboard");
    } catch (error) {
      showToastErrorMessage(error.message);
    }
  };

  /**
   *Api call to upload user profile picture
   */
  const updateUserProfilePicture = async () => {
    if (imageFile) {
      try {
        const response = await apiService.put(
          authEndpoits.uploadProfilePicture,
          imageFile,
          true
        );
        userProfileContext.setUserProfile(image);
      } catch (error) {
        showToastErrorMessage(error.message);
      }
    }
  };
  /**
   * handle form cancel
   */
  const handleFormBack = () => {
    window.history.back();
  };
  /**
   * Handles the form submission.
   *
   */
  const handleFormSubmit = (e) => {
    handleSubmit(onSubmit)();
  };

  /**
   * on click handles image changes
   * @param {*} event
   */
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    let formData = new FormData();
    formData.append("file", file);
    setImageFile(formData);

    reader.onload = (e) => {
      setImage(e.target.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <Fragment>
      {isloading ? (
        ""
      ) : (
        <Fragment>
          {userProfileData ? (
            <div className="edit-profile-container">
              <div className="edit-profile-left-container">
                <div className="profile-avatar-container">
                  <div className="avatar-con">
                    <img
                      src={image || cloudFrontUrl + userData.profile_url}
                      className="profile-avatar"
                    />
                    <div className="edit-icon-container">
                      <label htmlFor="profile-image-input">
                        <Icon name="editIcon" />
                      </label>
                      <input
                        id="profile-image-input"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="profile-avatar-text-container">
                  <span className="profile-name-container">
                    {userProfileData.first_name}
                  </span>
                  <span className="profile-name-container">
                    {userProfileData.last_name}
                  </span>
                </div>
              </div>
              <div className="edit-profile-right-container">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="edit-profile-form"
                >
                  {/* Full Name field */}
                  <div className="auth-dual-input-container mt-28">
                    <div className="auth-input-dual bg-padding">
                      <TextField
                        InputProps={{
                          className: "textField",
                        }}
                        variant="outlined"
                        id="firstName"
                        defaultValue={userProfileData.first_name || ""}
                        placeholder="First Name"
                        className="auth-input"
                        error={errors.firstName ? true : false}
                        {...registerData.firstNameRegister}
                      />
                      {errors.firstName && (
                        <div>
                          <div className="error-message">
                            {errors.firstName.message}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="auth-input-dual bg-padding">
                      <TextField
                        InputProps={{
                          className: "textField",
                        }}
                        defaultValue={userProfileData.last_name || ""}
                        variant="outlined"
                        id="lastName"
                        placeholder="Last Name"
                        className="auth-input "
                        error={errors.lastName ? true : false}
                        {...registerData.lastNameRegister}
                      />

                      {errors.lastName && (
                        <div>
                          <div className="error-message">
                            {errors.lastName.message}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Select Gender field */}
                  <div className="mt-16 bg-padding">
                    <TextField
                      select
                      variant="outlined"
                      id="gender"
                      className="auth-input custom-select"
                      error={errors.gender ? true : false}
                      defaultValue={userProfileData.gender || "Gender"}
                      {...registerData.genderRegister}
                    >
                      <MenuItem className="grey" value="Gender" disabled>
                        Select Gender
                      </MenuItem>
                      <MenuItem className="grey" value="MALE">
                        Male
                      </MenuItem>
                      <MenuItem value="FEMALE">Female</MenuItem>
                      <MenuItem value="OTHER">Other</MenuItem>
                      {/* Add more options as needed */}
                    </TextField>

                    {errors.gender && (
                      <div className="error-message">
                        {errors.gender.message}
                      </div>
                    )}
                  </div>
                  {/* Mobile Number field */}
                  <div className="mt-16 auth-dual-input-container bg-padding">
                    <div className="country-code">
                      <TextField
                        select
                        variant="outlined"
                        id="countryCode"
                        className="auth-input custom-select"
                        error={errors.countryCode ? true : false}
                        defaultValue={
                          userProfileData.country_code || "Country code"
                        }
                        {...registerData.countryCodeRegister}
                      >
                        <MenuItem
                          className="grey"
                          value="Country code"
                          disabled
                        >
                          Country code
                        </MenuItem>
                        {dropdownValues.countryValue.map((country) => {
                          return (
                            <MenuItem
                              key={country.uuid}
                              value={country.calling_code}
                            >
                              +{country.calling_code}-{country.country_iso3}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                      {errors.countryCode && (
                        <div>
                          <div className="error-message">
                            {errors.countryCode.message}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="auth-input-mobile">
                      <TextField
                        InputProps={{
                          className: "textField",
                        }}
                        variant="outlined"
                        id="mobileNumber"
                        defaultValue={userProfileData.mobile_no || ""}
                        placeholder="Mobile Number"
                        className="auth-input "
                        error={errors.mobileNumber ? true : false}
                        {...registerData.mobileRegister}
                      />
                      {errors.mobileNumber && (
                        <div>
                          <div className="error-message">
                            {errors.mobileNumber.message}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Select Board field */}
                  <div className="mt-16 bg-padding">
                    <TextField
                      select
                      variant="outlined"
                      id="board"
                      className="auth-input custom-select"
                      error={errors.board ? true : false}
                      defaultValue={userProfileData.board_uuid || "Board"}
                      {...registerData.boardRegister}
                    >
                      <MenuItem value="Board" disabled>
                        Board
                      </MenuItem>
                      {dropdownValues.boardValue.map((board) => {
                        return (
                          <MenuItem key={board.uuid} value={board.uuid}>
                            {board.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    {errors.board && (
                      <div className="error-message">
                        {errors.board.message}
                      </div>
                    )}
                  </div>
                  <div className="auth-dual-input-container mt-16">
                    {/* Select Standard field */}
                    <div className=" auth-input-dual bg-padding">
                      <TextField
                        select
                        variant="outlined"
                        id="standard"
                        className="auth-input custom-select"
                        error={errors.standard ? true : false}
                        defaultValue={
                          userProfileData.standard_uuid || "Standard"
                        }
                        {...registerData.standardRegister}
                      >
                        <MenuItem className="grey" value="Standard" disabled>
                          Standard
                        </MenuItem>
                        {dropdownValues.standardValue.map((standard) => {
                          return (
                            <MenuItem key={standard.uuid} value={standard.uuid}>
                              {standard.name}
                            </MenuItem>
                          );
                        })}

                        {/* Add more options as needed */}
                      </TextField>

                      {errors.standard && (
                        <div>
                          <div className="error-message">
                            {errors.standard.message}
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Select Medium field */}
                    <div className="auth-input-dual bg-padding">
                      <TextField
                        select
                        variant="outlined"
                        id="medium"
                        className="auth-input custom-select"
                        error={errors.medium ? true : false}
                        defaultValue={userProfileData.medium_uuid || "Medium"}
                        {...registerData.mediumRegister}
                      >
                        <MenuItem value="Medium" disabled>
                          Medium
                        </MenuItem>
                        {dropdownValues.mediumValue.map((medium) => {
                          return (
                            <MenuItem key={medium.uuid} value={medium.uuid}>
                              {medium.name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                      {errors.medium && (
                        <div>
                          <div className="error-message">
                            {errors.medium.message}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="edit-profile-btn-container">
                    <div className=" edit-profile-btn">
                      <TibButton
                        className="primaryButton"
                        // Trigger form submission
                        onClick={handleFormSubmit}
                      >
                        Update
                      </TibButton>
                    </div>
                    <div className=" edit-profile-btn">
                      <TibButton
                        className="secondoryButton"
                        buttontype="outlined"
                        // Trigger form submission
                        onClick={handleFormBack}
                      >
                        Cancel
                      </TibButton>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            ""
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditProfilePage;
