import { useState } from "react";
import { useForm } from "react-hook-form";
import { TextField, InputAdornment } from "@mui/material";
import { TibButton } from "../../button/button";
import Icon from "../../icon/icon";
import { passwordValidation } from "../../../validations/validation";
import ApiInterfaceServices from "../../../services/api-interface";
import { authEndpoits } from "../../../../utility/endpoints";
import {
  showToastErrorMessage,
  showToastSuccessMessage,
} from "../../../services/common-services";
import { useLocation, useNavigate } from "react-router-dom";

const ResetPasswordForm = () => {
  // State to toggle password visibility
  const [showPassword, setShowPassword] = useState(false);
  // State to toggle confirm password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  //usenevigate hook form react router dom to nevigate
  const navigate = useNavigate();

  // Get the current location from the react-router-dom's useLocation hook
  const location = useLocation();

  // Create a new URLSearchParams object based on the query string in the location
  const searchParams = new URLSearchParams(location.search);

  // Get the value of the "code" parameter from the search params
  const code = searchParams.get("code");

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onBlur", // Set validation mode to onBlur
  });

  /**
   * Toggles the visibility of the password field.
   */
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  /**
   * Toggles the visibility of the confirm password field.
   */
  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Validation rules for confirm password field
  const confirmPasswordValidation = {
    required: "Confirm Password is required",
    validate: (value) => {
      const password = watch("password");
      return value === password || "Passwords do not match";
    },
  };

  /**
   * Returns the appropriate error message based on the error type.
   * @param {object} error - The error object from react-hook-form.
   * @returns {string} - The error message.
   */
  const getErrorMessage = (error) => {
    if (error.type === "required") {
      return "Password is required";
    }
    if (error.type === "minLength") {
      return "Password should have at least 8 characters";
    }
    if (error.type === "alphanumeric") {
      return error.message;
    }
    return "Invalid password";
  };

  //Json for the input field register
  const registerData = {
    // Property: passwordRegister
    // Description: This property represents the password registration field and validates the password using passwordValidation function.
    passwordRegister: register("password", passwordValidation),

    // Property: confirmPasswordRegister
    // Description: This property represents the confirmation password registration field and validates the confirmation password using confirmPasswordValidation function.
    confirmPasswordRegister: register(
      "confirmPassowrd",
      confirmPasswordValidation
    ),
  };

  /**
   * Handle form submission
   * @param {object} data - The form data to be submitted
   */
  const apiService = new ApiInterfaceServices();
  /**
   * api call.
   */
  const onSubmit = async (data) => {
    const { password, confirmPassowrd } = data;

    if (code && code !== null) {
      try {
        // Send a POST request to the "/auth/sign_in" endpoint with the provided data
        const response = await apiService.post(
          authEndpoits.resetPassword,
          {
            resetCode: code,
            newPassword: password,
            confirm_password: confirmPassowrd,
          },
          true
        );

        if (response) {
          showToastSuccessMessage("Success! Password reset sucessfully !!");
          navigate("/auth");
        }
      } catch (error) {
        {
          error.response
            ? showToastErrorMessage(error.response.data.message)
            : showToastErrorMessage(error.message);
        }
      }
    }
  };

  /**
   * Handles the form submission.
   *
   */

  const handleSignup = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <div className="width-50 gradient">
      <div className="auth-input-container">
        <div className="header primary-color">
          <span>Enter new password !!</span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Password field */}
          <div className="m-40">
            <TextField
              InputProps={{
                className: "textField",
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={handleTogglePassword}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon
                      name={showPassword ? "passwordEyeHide" : "passwordEye"}
                      color="black"
                    />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              className="auth-input "
              error={errors.password ? true : false}
              type={showPassword ? "text" : "password"}
              id="password"
              placeholder="New Password"
              {...registerData.passwordRegister}
            />

            {errors.password && (
              <div className="error-message">
                {getErrorMessage(errors.password)}
              </div>
            )}
          </div>

          {/* Confirm Password field */}
          <div className="m-20">
            <TextField
              InputProps={{
                className: "textField",
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={handleToggleConfirmPassword}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon
                      name={
                        showConfirmPassword ? "passwordEyeHide" : "passwordEye"
                      }
                      color="black"
                    />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              className="auth-input "
              error={errors.confirmPassowrd ? true : false}
              type={showConfirmPassword ? "text" : "password"}
              id="confirmPassowrd"
              placeholder="Confirm new Password"
              {...registerData.confirmPasswordRegister}
            />

            {errors.confirmPassowrd && (
              <div className="error-message">
                {errors.confirmPassowrd.message}
              </div>
            )}
          </div>

          {/* Reset password button */}
          <div className="m-20">
            <TibButton
              className="primaryButton"
              // Trigger form submission
              onClick={handleSignup}
            >
              Reset Password
            </TibButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
