import React from "react";
import { useContext } from "react";
import { BreadCrumbContext } from "../../services/context/subcontext";
import Icon from "../icon/icon";
import "./breadcrumbs.scss";
import { Link } from "react-router-dom";

const Breadcrumbs = () => {
  const breadCrumbs = useContext(BreadCrumbContext);
  const crumb = breadCrumbs.breadCrumb;

  return (
    <div className="bread-crumbs">
      <Icon name={"breadcrumbs"} />
      {crumb ? (
        crumb.map((crumb, index) => {
          return (
            <div key={crumb.url || index} className="crumb">
              {index > 0 && <Icon name={"breadcrumbsLayers"} />}
              {crumb.url ? (
                <Link to={crumb.url}>{crumb.label}</Link>
              ) : (
                <span className="crumb-active">{crumb.label}</span>
              )}
            </div>
          );
        })
      ) : (
        <div className="no-data-found-container">No Data Found</div>
      )}
    </div>
  );
};

export default Breadcrumbs;
