export const setQuestionBank = (paperName) => {
  window.gtag("event", "question_bank", {
    question_bank_name: paperName,
  });
};

export const setLoggedinUser = (userName) => {
  window.gtag("event", "user_with_login", {
    user_with_login: userName,
  });
};

export const setUserWithoutLogin = () => {
  window.gtag("event", "user_without_login", {
    user_without_login: "user",
  });
};
