import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { TibButton } from "../../button/button";
import { emailValidation } from "../../../validations/validation";
import ApiInterfaceServices from "../../../services/api-interface";
import { authEndpoits } from "../../../../utility/endpoints";
import {
  showToastErrorMessage,
  showToastSuccessMessage,
} from "../../../services/common-services";

const ForgetPasswordForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // Set validation mode to onBlur
    mode: "onBlur",
  });

  //Json for the input field register
  const registerData = {
    // Property: emailRegister
    // Description: This property represents the email registration field and validates the email using emailValidation function.
    emailRegister: register("email", emailValidation),
  };

  const apiService = new ApiInterfaceServices();
  /**
   * api call.
   */
  const onSubmit = async (data) => {
    try {
      // Send a POST request to the "/auth/sign_in" endpoint with the provided data
      const response = await apiService.post(
        authEndpoits.forgetPassword,
        data,
        true
      );

      if (response) {
        showToastSuccessMessage(
          "Success! Password reset email sent to your registered email address. Please check your inbox."
        );
      }
    } catch (error) {
      {
        error.response
          ? showToastErrorMessage(error.response.data.message)
          : showToastErrorMessage(error.message);
      }
    }
  };

  /**
   * Handles the form submission.
   *
   */
  const handleformSubmit = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <div className="width-50 gradient">
      <div className="auth-input-container">
        <div className="header primary-color">
          <span>Enter your email to</span>
          <br />
          <span>reset password!!</span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Email field */}
          <div className="m-40">
            <TextField
              InputProps={{
                className: "textField",
              }}
              variant="outlined"
              id="email"
              placeholder="Email"
              className="auth-input "
              error={errors.medium ? true : false}
              {...registerData.emailRegister}
            />
            {errors.email && (
              <div className="error-message">{errors.email.message}</div>
            )}
          </div>

          {/* forget password button */}
          <div className="m-40">
            <TibButton
              className="primaryButton"
              // Trigger form submission
              onClick={handleformSubmit}
            >
              Forget Password
            </TibButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgetPasswordForm;
