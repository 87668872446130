import { AuthSideBar } from "../shared/components/auth-sidebar/auth-sidebar";
import ResetPasswordForm from "../shared/components/auth/reset-password-form/reset-password-form";

export default function ResetPasswordPage() {
  return (
    <div className="auth-container">
      <AuthSideBar title="Reset password for" />
      <ResetPasswordForm />
    </div>
  );
}
