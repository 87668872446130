import { redirect } from "react-router-dom";

/**
 * Set an item in the local storage.
 * @param {string} key - The key for the item.
 * @param {any} value - The value to be stored. If it's an object, it will be converted to a string using JSON.stringify.
 */
export const setLocalStorageItem = (key, value) => {
  if (typeof value === "object") {
    // Convert the value to a string using JSON.stringify if it's an object
    value = JSON.stringify(value);
  }
  // Set the item in the local storage
  window.localStorage.setItem(key, value);
};

/**
 * Get an item from the local storage.
 * @param {string} key - The key for the item.
 * @returns {any} - The retrieved value. If it's a JSON string, it will be parsed into an object.
 */
export const getLocalStorageItem = (key) => {
  // Get the item from the local storage
  let value = window.localStorage.getItem(key);

  // Return the retrieved value
  return value;
};

export function checkAuthLoader() {
  const token = getLocalStorageItem("access_token");

  if (!token) {
    return redirect("/auth");
  }
  return null;
}
