import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TextField, InputAdornment } from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import googleIcon from "../../../../assets/google.png";
import { TibButton } from "../../button/button";
import Icon from "../../icon/icon";
import "./login-form.scss";
import {
  emailValidation,
  passwordValidation,
} from "../../../validations/validation";
import ApiInterfaceServices from "../../../services/api-interface";
import { authEndpoits } from "../../../../utility/endpoints";
import { setLocalStorageItem } from "../../../../utility/utility";
import { LoginSocialGoogle } from "reactjs-social-login";
import { showToastErrorMessage } from "../../../services/common-services";

const LoginForm = () => {
  // State to toggle password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [providerId, setProvideID] = useState("");
  const [socialLoginName, setSocailLoginName] = useState();

  //usenevigate hook form react router dom to nevigate
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // Set validation mode to onBlur
    mode: "onBlur",
  });
  const apiService = new ApiInterfaceServices();

  const location = useLocation();

  useEffect(() => {
    if (providerId && accessToken) {
      try {
        let Obj = {
          access_token: accessToken,
          provider_id: providerId,
        };
        // Send a POST request to the "/auth/sign_in" endpoint with the provided data
        const res = apiService
          .post(authEndpoits.socialLogin, Obj, true)
          .then((res) => {
            // Store the access token and refresh token in the local storage
            setLocalStorageItem("access_token", res.data.access_token);
            setLocalStorageItem("refresh_token", res.data.refresh_token);
            setLocalStorageItem("user", socialLoginName);
            // Navigate to the "/dashbord" route
            navigate("/dashboard");
          });
      } catch (error) {
        // eslint-disable-next-line no-lone-blocks
        {
          error.response
            ? showToastErrorMessage(error.response.data.message)
            : showToastErrorMessage(error.message);
        }
      }
    }
  });

  // Validation rules...

  //Json for the input field register
  const registerData = {
    // Property: emailRegister
    // Description: This property represents the email registration field and validates the email using emailValidation function.
    emailRegister: register("email", emailValidation),

    // Property: passwordRegister
    // Description: This property represents the password registration field and validates the password using passwordValidation function.
    passwordRegister: register("password", passwordValidation),
  };

  /**
   * Gets the appropriate error message based on the error type.
   * @param {object} error - The error object from useForm.
   * @returns {string} - The error message.
   */
  const getErrorMessage = (error) => {
    if (error.type === "required") {
      return "Password is required";
    }
    if (error.type === "minLength") {
      return "Password should have at least 8 characters";
    }
    if (error.type === "alphanumeric") {
      return error.message;
    }
  };

  /**
   * Handles the password visibility toggle.
   */
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  /**
   * Handle form submission
   * @param {object} data - The form data to be submitted
   */
  const onSubmit = async (data) => {
    try {
      // Send a POST request to the "/auth/sign_in" endpoint with the provided data
      const response = await apiService.post(authEndpoits.signin, data, true);
      // Store the access token and refresh token in the local storage

      setLocalStorageItem("access_token", response.data.access_token);
      setLocalStorageItem("refresh_token", response.data.refresh_token);

      // Navigate to the "/dashbord" route
      // eslint-disable-next-line no-lone-blocks
      {
        location.state
          ? navigate(location.state.callbackUrl)
          : navigate("/dashboard");
      }
    } catch (error) {
      // eslint-disable-next-line no-lone-blocks
      {
        error.response
          ? showToastErrorMessage(error.response.data.message)
          : showToastErrorMessage(error.message);
      }
    }
  };
  /**
   * Handles the form submission.
   *
   */
  const handleFormSubmit = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <div className="width-50 gradient">
      <div className="auth-input-container">
        <div className="header primary-color">
          <span>Glad to see you Sign in now!!</span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Email field */}
          <div className="m-40">
            <TextField
              InputProps={{
                className: "textField",
              }}
              variant="outlined"
              id="email"
              placeholder="Email"
              className="auth-input "
              error={errors.email ? true : false}
              {...registerData.emailRegister}
            />
            {errors.email && (
              <div className="error-message">{errors.email.message}</div>
            )}
          </div>

          {/* Password field */}
          <div className="m-20">
            <TextField
              InputProps={{
                className: "textField",
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={handleTogglePassword}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon
                      name={showPassword ? "passwordEyeHide" : "passwordEye"}
                      color="black"
                    />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              className="auth-input "
              error={errors.password ? true : false}
              type={showPassword ? "text" : "password"}
              id="password"
              placeholder="Password"
              {...registerData.passwordRegister}
            />

            {errors.password && (
              <div className="error-message">
                {getErrorMessage(errors.password)}
              </div>
            )}
          </div>
          <div className="mt-4 text-end">
            <NavLink
              to="/auth/forget-password"
              className="navLink font12 font-medium"
            >
              Forgot Password?
            </NavLink>
          </div>

          {/* Sign-in button */}
          <div className="m-20">
            <TibButton
              className="primaryButton"
              // Trigger form submission
              onClick={handleFormSubmit}
            >
              Sign in
            </TibButton>
          </div>
        </form>

        {/* Other elements */}
        <div>
          {/* OR separator */}
          <div className="text-center">
            <p className="line ">
              <span>OR</span>
            </p>
          </div>

          {/* Social login buttons */}
          <div className="auth-social-container">
            {/* Google */}
            <div className="auth-link-button">
              <LoginSocialGoogle
                client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                onResolve={({ provider, data }) => {
                  setAccessToken(data.access_token);
                  const userName = data.name;
                  setSocailLoginName(userName);
                  setProvideID(provider + ".com");
                }}
              >
                <TibButton className="secondoryButton" buttontype="outlined">
                  <img src={googleIcon} alt="Google" />
                </TibButton>
              </LoginSocialGoogle>
            </div>
          </div>

          {/* Sign up link */}
          <div className="m-20 text-center">
            <NavLink to="/auth/signup" className="navLink">
              Don't have an account?
              <span className="navLink-text-bold"> Sign up</span>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
