function getTimeAgo(timestamp) {
  const currentDate = new Date();
  const commentDate = new Date(timestamp);
  const timeDiff = Math.abs(currentDate.getTime() - commentDate.getTime());
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (seconds < 60) {
    return `${seconds} seconds ago`;
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return commentDate.toLocaleString(undefined, options);
  }
}

export default getTimeAgo;
