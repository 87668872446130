import { Navigate, Outlet } from "react-router-dom";
import Dashbord from "../pages/main/dashboard/dashbord";
import QuestionPaperList from "../pages/main/questionpaper-list/questionpaper-list";
import PdfView from "../pages/main/pdf-view/pdf-view";
import EditProfilePage from "../pages/main/edit-profile/edit-profile";
import { SearchFilterProvider } from "../shared/services/context/subcontext";

const ChildRoutes = [
  { path: "", element: <Dashbord /> },
  { path: "questionpaper-list", element: <QuestionPaperList /> },
  { path: "pdf-view", element: <PdfView /> },
  { path: "edit-profile", element: <EditProfilePage /> },
];

// Import your components (PdfView, QuestionPaperList, Dashbord, etc.) here

export const DashborRoutes = [
  {
    path: "",
    element: <Navigate to="dashboard" />,
  },
  {
    path: "dashboard",
    element: (
      <SearchFilterProvider>
        <Outlet />
      </SearchFilterProvider>
    ),
    children: [...ChildRoutes],
  },
];
