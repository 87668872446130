import "./login-popup.scss";
import popupImage from "../../../assets/popup-image.svg";
import { TibButton } from "../button/button";
import { Dialog, DialogTitle } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LoginPopup = (props) => {
  const navigate = useNavigate();
  const { onClose, open, url } = props;

  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"lg"}
      open={open}
      className="mui-popup"
    >
      <DialogTitle>
        <div className="popup">
          <div className="popup-container">
            <div className="popup-container-left">
              <div>
                <div className="popup-container-text-heading">
                  <div>Oopss..!! </div>
                  <div>Please Login Your Account</div>
                </div>
                <div className="popup-container-sub-text">
                  In order to access a broader range of content, it is necessary
                  for you to log in first. Logging in will enable you to unlock
                  additional information and resources, thereby enhancing your
                  overall experience and providing you with more comprehensive
                  insights and materials.
                </div>
              </div>
              <div className="popup-button-container">
                <TibButton
                  className="primaryButton"
                  onClick={() =>
                    navigate("/auth/login", { state: { callbackUrl: url } })
                  }
                >
                  Login
                </TibButton>
              </div>
            </div>
            <div className="popup-container-right">
              <img src={popupImage} alt="" />
            </div>
          </div>
        </div>
      </DialogTitle>
    </Dialog>
  );
};

export default LoginPopup;
