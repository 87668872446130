import SignupForm from "../shared/components/auth/signup-form/signup-form";
import { AuthSideBar } from "../shared/components/auth-sidebar/auth-sidebar";

export default function SignupPage() {
  return (
    <div className="auth-container">
      <AuthSideBar title={"Register for"} />
      <SignupForm />
    </div>
  );
}
