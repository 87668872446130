import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  useRef,
} from "react";
import { Checkbox, FormControlLabel, MenuItem, TextField } from "@mui/material";
import "./questionpaper-list.scss";
import Icon from "../../../shared/components/icon/icon";
import { TibButton } from "../../../shared/components/button/button";
import { useForm } from "react-hook-form";
import paperlogo from "../../../assets/paper.svg";
import ApiInterfaceServices from "../../../shared/services/api-interface";
import { authEndpoits } from "../../../utility/endpoints";
import { showToastErrorMessage } from "../../../shared/services/common-services";
import { useNavigate } from "react-router";
import {
  BreadCrumbContext,
  SearchFilterContext,
} from "../../../shared/services/context/subcontext";
import Adsense from "../../../shared/components/ad-sense/ad-sense";
import LoginPopup from "../../../shared/components/login-popup/login-popup";
import { getLocalStorageItem } from "../../../utility/utility";
import { setQuestionBank } from "../../../utility/google-analytics";

/**
 *
 * @returns exports QuestionPaperList page
 */
const QuestionPaperList = () => {
  const BreadCrumbsData = [
    { label: "Dashboard", url: "dashboard" },
    { label: "Question Paper List", url: "" },
  ];

  // Using a context hook to access Breadcrumbs data
  const BreadCrumbs = useContext(BreadCrumbContext);

  // State hook to manage login popup
  const [open, setOpen] = useState(false);

  // Using a context hook to access search filter data
  const searchFilterContext = useContext(SearchFilterContext);
  const searchFilterData = searchFilterContext.searchFilter;

  // State hooks to manage dropdown visibility
  const [dropdownState, setDropdownState] = useState({
    isBoardDropdownOpen: false,
    isYearDropdownOpen: false,
    isMediumDropdownOpen: false,
    isSubjectDropdownOpen: false,
    isStandardDropdownOpen: false,
  });

  // State hook to manage paper list data
  const [paperListData, setPaperListData] = useState([]);

  // State hooks to manage various paper filters
  const [paperSubject, setPaperSubject] = useState("");
  const [paperYear, setPaperYear] = useState();
  const [paperBoard, setPaperBoard] = useState(searchFilterData.board);
  const [paperMedium, setPaperMedium] = useState(searchFilterData.medium);
  const [paperPaper, setPaperPaper] = useState("");
  const [paperStandard, setPaperStandard] = useState(searchFilterData.standard);

  // State hook to manage additional data
  const [dataState, setDataState] = useState({
    subjectData: [],
    boardData: [],
    mediumData: [],
    standardData: [],
  });

  // State hooks to manage paper page and selected year
  const [paperPage, setPaperPage] = useState("");
  // const [selectedYear, setSelectedYear] = useState("");

  // State hook to manage the current page number of the PDF
  const [currPage, setCurrPage] = useState(1);

  // State hook to manage the total number of pages in the PDF
  const [totalPage, setTotalPage] = useState();

  // Creating an instance of the API service
  const apiService = new ApiInterfaceServices();

  // State hook to manage filter checkbox
  const [filters, setFilters] = useState({
    isYearFilterOpen: false,
    isMediumFilterOpen: false,
    isBoardFilterOpen: false,
    isStandardFilterOpen: false,
  });

  // Reference to the scroll container
  const scrollContainerRef = useRef(null);

  /**
   * To update a specific dropdown state:
   * @param {*} dropdownName
   * @param {*} isOpen
   */
  const updateDropdownState = (dropdownName, isOpen) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [dropdownName]: isOpen,
    }));
  };
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    // Set validation mode to onBlur
    mode: "onBlur",
  });
  /**
   * download api call
   * @param {*} data
   */
  const handleDownload = (data) => {
    apiService
      .get(authEndpoits.downloadPaper + "/" + data.file_uuid, true)
      .then((response) => {
        const downloadUrl = response.data.data.download_url;
        window.location.href = downloadUrl;
      })
      .catch((error) => {
        showToastErrorMessage(error.message);
      });
  };

  /**
   * handle onclick view
   * @param {*} data
   */
  const handleView = async (data) => {
    setQuestionBank(data.paper);
    navigate(`/dashboard/pdf-view?id=${data.paper_uuid}`);
  };

  // Validation rules for board selection
  const boardValidation = {
    validate: {
      notDefaultValue: (value) =>
        value !== "Select Board" || "Please select a board",
    },
  };

  // Validation rules for standard selection
  const standardValidation = {
    validate: {
      notDefaultValue: (value) =>
        value !== "Select Standard" || "Please select a standard",
    },
  };

  // Validation rules for medium selection
  const mediumValidation = {
    validate: {
      notDefaultValue: (value) =>
        value !== "Select Medium" || "Please select a medium",
    },
  };

  // Validation rules for year selection
  const yearValidation = {
    required: "Please enter a year",
    pattern: {
      value: /^\d{4}$/,
      message: "Invalid year format. Please enter a valid 4-digit year.",
    },
  };
  const registerData = {
    boardRegister: register("board", boardValidation),
    yearRegister: register("year"),
    mediumRegister: register("medium", mediumValidation),
    standardRegister: register("standard", standardValidation),
  };
  // To access the data arrays, you can use the properties of the dataState object:
  const { subjectData, boardData, mediumData, standardData } = dataState;

  useEffect(() => {
    BreadCrumbs.setBreadCrumb(BreadCrumbsData);
  });

  useEffect(() => {
    setValue("year", paperYear);
    setValue("board", paperBoard);
    setValue("medium", paperMedium);
    setValue("standard", paperStandard);

    // Check if there is a selected board and set isBoardFilterOpen accordingly
    if (getValues("board")) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        isBoardFilterOpen: true,
      }));
    }

    // Check if there is a selected medium and set isMediumFilterOpen accordingly
    if (getValues("medium")) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        isMediumFilterOpen: true,
      }));
    }
    // Check if there is a selected standard and set isStandardFilterOpen accordingly
    if (getValues("standard")) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        isStandardFilterOpen: true,
      }));
    }
    // Check if there is a selected year and set isYearFilterOpen accordingly
    if (getValues("year")) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        isYearFilterOpen: true,
      }));
    }

    Promise.all([
      getQuestionPaperData(),
      getSubjectData(),
      getBoardData(),
      getMediumData(),
      getStandardData(),
    ]);
  }, [
    paperSubject,
    paperBoard,
    paperMedium,
    paperPage,
    paperStandard,
    paperPaper,
    paperYear,
  ]);
  useEffect(() => {
    getQuestionPaperData();
  }, [currPage]);

  /**
   * Api call - get subject
   */
  const getSubjectData = async () => {
    try {
      const response = await apiService.get(authEndpoits.userSubjects);
      const subjects = response.data.data;

      setDataState((prevState) => ({
        ...prevState,
        subjectData: subjects,
      }));
    } catch (error) {
      showToastErrorMessage(error.message);
    }
  };
  /**
   * Api call - get board
   */
  const getBoardData = async () => {
    try {
      const response = await apiService.get(authEndpoits.board, true);
      const board = response.data.data;
      setDataState((prevState) => ({
        ...prevState,
        boardData: board,
      }));
    } catch (error) {
      showToastErrorMessage(error.message);
    }
  };
  /**
   * Api call - get medium
   */
  const getMediumData = async () => {
    try {
      const response = await apiService.get(authEndpoits.medium, true);
      const medium = response.data.data;
      setDataState((prevState) => ({
        ...prevState,
        mediumData: medium,
      }));
    } catch (error) {
      showToastErrorMessage(error.message);
    }
  };
  /**
   * Api call - get standard
   */
  const getStandardData = async () => {
    try {
      const response = await apiService.get(authEndpoits.standard, true);
      const standard = response.data.data;
      setDataState((prevState) => ({
        ...prevState,
        standardData: standard,
      }));
    } catch (error) {
      showToastErrorMessage(error.message);
    }
  };

  /**
   * Api call - get question paper list
   * params passed for filter
   */

  const getQuestionPaperData = async () => {
    const params = {
      year: getValues("year"),
      board: getValues("board"),
      medium: getValues("medium"),
      standard: getValues("standard"),
      paper: paperPaper,
      subject: paperSubject,
      page: currPage,
      per_page: 10,
    };

    try {
      const response = await apiService.get(
        authEndpoits.listPapers,
        true,
        params
      );
      const data = response.data.data;
      const metaData = response.data.meta;
      const totalPages = Math.ceil(metaData.total_rows / metaData.per_page);
      setTotalPage(totalPages);

      if (currPage === 1) {
        // If it's the first page, replace the existing data with the data of the first page only
        setPaperListData(data);
      } else {
        // If it's not the first page, fetch data for the current page and combine it with the existing data
        const response = await apiService.get(
          authEndpoits.listPapers,
          true,
          params
        );
        const currentPageData = response.data.data;
        const combinedData = [...paperListData, ...currentPageData];
        setPaperListData(combinedData);
      }
    } catch (error) {
      showToastErrorMessage(error.message);
    }
  };

  // check if there is more page
  const hasMorePage = currPage < totalPage;

  /**
   * handle comment scroll for pagination
   */
  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;

      // Check if the container has scrollable content
      if (scrollHeight > clientHeight) {
        if (scrollTop + clientHeight === scrollHeight) {
          // Increment the current page by 1
          if (hasMorePage) {
            setCurrPage((prevPage) => prevPage + 1);

            // Load more data or perform any actions you need
            getQuestionPaperData();
          }
        }
      }

      // Store the current scrollHeight for future comparison
      container.setAttribute("data-scroll-height", scrollHeight);
    }
  };

  /**
   * Handles the cancel button click event.
   */
  const handleCancel = () => {
    const container = scrollContainerRef.current;

    // Scroll to the top of the container
    if (container) {
      container.scrollTop = 0;
    }
    setCurrPage(1);
    setFilters({
      isYearFilterOpen: false,
      isMediumFilterOpen: false,
      isBoardFilterOpen: false,
      isStandardFilterOpen: false,
    });
    setPaperYear();
    setPaperBoard();
    setPaperMedium();
    setPaperStandard();
    setPaperPaper();
    setPaperSubject();
  };

  /**
   * handle year checkbox change
   * @param {*} event
   */

  const handleYearCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setFilters((prevFilters) => ({
      ...prevFilters,
      isYearFilterOpen: isChecked,
    }));

    // Remove the selected value when the checkbox is unchecked
    if (!isChecked) {
      setValue("year", "");
    }
  };

  /**
   * handle board change
   * @param {*} event
   */
  const handleYearChange = (event) => {
    const yearValue = event.target.value;
    setValue("year", yearValue);
    updateDropdownState("isYearDropdownOpen", false);
  };

  /**
   * handle board checkbox change
   * @param {*} event
   */

  const handleBoardCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setFilters((prevFilters) => ({
      ...prevFilters,
      isBoardFilterOpen: isChecked,
    }));

    // Remove the selected value when the checkbox is unchecked
    if (!isChecked) {
      setValue("board", "");
    }
  };

  /**
   * handle board change
   * @param {*} event
   */
  const handleBoardChange = (event) => {
    const boardValue = event.target.value;
    setValue("board", boardValue);
    updateDropdownState("isBoardDropdownOpen", false);
  };

  /**
   * handle medium checkbox change
   * @param {*} event
   */
  const handleMediumCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setFilters((prevFilters) => ({
      ...prevFilters,
      isMediumFilterOpen: isChecked,
    }));

    // Remove the selected value when the checkbox is unchecked
    if (!isChecked) {
      setValue("medium", "");
    }
  };

  /**
   * handle medium change
   * @param {*} event
   */
  const handleMediumChange = (event) => {
    const mediumValue = event.target.value;
    setValue("medium", mediumValue);
    updateDropdownState("isMediumDropdownOpen", false);
  };

  /**
   * handle standard checkbox change
   * @param {*} event
   */
  const handleStandardCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setFilters((prevFilters) => ({
      ...prevFilters,
      isStandardFilterOpen: isChecked,
    }));
    // Remove the selected value when the checkbox is unchecked
    if (!isChecked) {
      setValue("standard", "");
    }
  };

  /**
   * handle standard change
   * @param {*} event
   */
  const handleStandardChange = (event) => {
    const standardValue = event.target.value;
    setValue("standard", standardValue);
    updateDropdownState("isStandardDropdownOpen", false);
  };

  /**
   * handle apply filter submit
   * @param {*} e
   */
  const handleFormSubmit = (e) => {
    handleSubmit(onSubmit)();
  };
  const onSubmit = async (data) => {
    await checkCheckbox(data);
    setSubmitData(data);
  };
  /**
   * set submitted data from filter
   * @param {*} data
   */
  const setSubmitData = (data) => {
    setPaperYear(data.year);
    setPaperBoard(data.board);
    setPaperMedium(data.medium);
    setPaperStandard(data.standard);
    setPaperPaper(data.paper);
  };

  // if checkbox uncheck remove value from filter
  const checkCheckbox = async (data) => {
    const {
      isBoardFilterOpen,
      isYearFilterOpen,
      isMediumFilterOpen,
      isStandardFilterOpen,
    } = filters;
    if (!isBoardFilterOpen) {
      data.board = getValues("board");
    }
    if (!isYearFilterOpen) {
      data.year = getValues("year");
    }
    if (!isMediumFilterOpen) {
      data.medium = getValues("medium");
    }
    if (!isStandardFilterOpen) {
      data.standard = getValues("standard");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  // Fetching the value of the form field with name "year"
  const yearValue = getValues("year");

  // Fetching the value of the form field with name "board"
  const boardValue = getValues("board");

  // Fetching the value of the form field with name "medium"
  const mediumValue = getValues("medium");

  // Fetching the value of the form field with name "standard"
  const standardValue = getValues("standard");

  // Get User Name form local storage
  const userName = getLocalStorageItem("user");

  return (
    <Fragment>
      <div className="qp-container">
        <div className="qp-list">
          <div className="qp-container-left ">
            <div className="bg-padding">
              <TextField
                select
                variant="outlined"
                id="subject"
                className="auth-input custom-select"
                // Set the value prop to subjectName
                value={paperSubject || "Select Subject"}
              >
                <MenuItem value="Select Subject" disabled>
                  Select Subject
                </MenuItem>
                {subjectData &&
                  subjectData.map((sub) => {
                    return (
                      <MenuItem
                        key={sub.uuid}
                        value={sub.name}
                        onClick={() => {
                          setPaperSubject(sub.name);
                          setCurrPage(1);
                        }}
                      >
                        {sub.name}
                      </MenuItem>
                    );
                  })}
              </TextField>
              <div className="horizontal line "></div>
            </div>

            <div className="filter-form-container">
              <form className="filter-form" onSubmit={handleSubmit(onSubmit)}>
                <span className="filter-checkbox">Filters</span>

                <div className="horizontal-scroll">
                  {/* year */}
                  <div className="checbox-unit">
                    <FormControlLabel
                      className="filter-checkbox"
                      control={<Checkbox checked={filters.isYearFilterOpen} />}
                      label="Year"
                      onChange={handleYearCheckboxChange}
                      name="YearFilter"
                    />
                    {filters.isYearFilterOpen && (
                      <div className="filter-input">
                        <TextField
                          variant="outlined"
                          id="year"
                          className="auth-input "
                          error={errors.year ? true : false}
                          placeholder="Enter Year"
                          {...registerData.yearRegister}
                          // Set the value of the board
                          value={yearValue}
                          // Call the handleBoardChange function on change
                          onChange={handleYearChange}
                        ></TextField>
                        {errors.year && (
                          <span className="error-message">
                            {errors.year.message}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  {/* ---year-over--- */}
                  {/* board */}
                  <div className="checbox-unit">
                    <FormControlLabel
                      className="filter-checkbox"
                      control={<Checkbox checked={filters.isBoardFilterOpen} />}
                      label="Board"
                      onChange={handleBoardCheckboxChange}
                      name="BoardFilter"
                    />
                    {filters.isBoardFilterOpen && (
                      <div className="filter-input bg-padding">
                        <TextField
                          select
                          variant="outlined"
                          id="board"
                          className="auth-input custom-select"
                          error={errors.board ? true : false}
                          defaultValue={boardValue || "Select Board"}
                          {...registerData.boardRegister}
                          value={boardValue || "Select Board"}
                          // Call the handleBoardChange function on change
                          onChange={handleBoardChange}
                        >
                          <MenuItem value="Select Board" disabled>
                            Select Board
                          </MenuItem>
                          {boardData &&
                            boardData.map((board) => {
                              return (
                                <MenuItem key={board.uuid} value={board.name}>
                                  {board.name}
                                </MenuItem>
                              );
                            })}
                        </TextField>
                        {errors.board && (
                          <span className="error-message">
                            {errors.board.message}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  {/* ---Board-over--- */}
                  {/* medium */}
                  <div className="checbox-unit">
                    <FormControlLabel
                      className="filter-checkbox"
                      control={
                        <Checkbox checked={filters.isMediumFilterOpen} />
                      }
                      label="Medium"
                      onChange={handleMediumCheckboxChange}
                      name="MediumFilter"
                    />
                    {filters.isMediumFilterOpen && (
                      <div className="filter-input bg-padding">
                        <TextField
                          select
                          variant="outlined"
                          id="medium"
                          className="auth-input custom-select"
                          error={errors.medium ? true : false}
                          defaultValue={mediumValue || "Select Medium"}
                          {...registerData.mediumRegister}
                          // Set the value of the board
                          value={mediumValue || "Select Medium"}
                          // Call the handleBoardChange function on change
                          onChange={handleMediumChange}
                        >
                          <MenuItem value="Select Medium" disabled>
                            Select Medium
                          </MenuItem>

                          {mediumData &&
                            mediumData.map((medium) => {
                              return (
                                <MenuItem key={medium.uuid} value={medium.name}>
                                  {medium.name}
                                </MenuItem>
                              );
                            })}
                        </TextField>
                        {errors.medium && (
                          <span className="error-message">
                            {errors.medium.message}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  {/* ---medium-over--- */}
                  {/* standard */}
                  <div className="checbox-unit">
                    <FormControlLabel
                      className="filter-checkbox"
                      control={
                        <Checkbox checked={filters.isStandardFilterOpen} />
                      }
                      label="Standard"
                      onChange={handleStandardCheckboxChange}
                      name="StandardFilter"
                    />
                    {filters.isStandardFilterOpen && (
                      <div className="filter-input bg-padding">
                        <TextField
                          select
                          variant="outlined"
                          id="medium"
                          className="auth-input custom-select"
                          error={errors.standard ? true : false}
                          defaultValue={standardValue || "Select Standard"}
                          {...registerData.standardRegister}
                          // Set the value of the board
                          value={standardValue || "Select Standard"}
                          // Call the handleBoardChange function on change
                          onChange={handleStandardChange}
                        >
                          <MenuItem value="Select Standard" disabled>
                            Select Standard
                          </MenuItem>

                          {standardData &&
                            standardData.map((standard) => {
                              return (
                                <MenuItem
                                  key={standard.uuid}
                                  value={standard.name}
                                >
                                  {standard.name}
                                </MenuItem>
                              );
                            })}
                        </TextField>
                        {errors.standard && (
                          <span className="error-message">
                            {errors.standard.message}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  {/* ---standard-over--- */}
                </div>
                <div className="filter-button">
                  <TibButton
                    className="primaryButton"
                    // Trigger form submission
                    onClick={handleFormSubmit}
                  >
                    Apply
                  </TibButton>
                </div>
                <div className="filter-button-ol">
                  <TibButton
                    className="secondoryButton"
                    buttontype="outlined"
                    // Clear the form and selected filters
                    onClick={handleCancel}
                  >
                    Reset
                  </TibButton>
                </div>
              </form>
            </div>
            {/* <div className="filter-advertisement-container"> */}
            <Adsense
              style={{ display: "flex", width: "154px", height: "60px " }}
              slot="1234567890"
            />
            {/* </div> */}
          </div>
          <div className="qp-container-right">
            <div className="qp-container-right-heading">
              <div>
                <span className="qp-heading">
                  {paperSubject ? "Board Papers of " + paperSubject : "Papers"}
                </span>
              </div>
            </div>
            <div className="horizontal line m-20">
              <div
                className="qp-list-container"
                onScroll={handleScroll}
                ref={scrollContainerRef}
              >
                {paperListData.length !== 0 ? (
                  paperListData.map((list, index) => {
                    const isMultipleOfFour = (index + 1) % 4 === 0;
                    const isFirstFour = paperListData.length < 4;
                    return (
                      <Fragment>
                        <div className="qp-details-container" key={index}>
                          <div className="qpdc-left">
                            <img
                              className="cursor-pointer"
                              src={paperlogo}
                              alt="update"
                              onClick={() => handleView(list)}
                            />
                            <div className="h-48">
                              <div
                                className="cursor-pointer"
                                onClick={() => handleView(list)}
                              >
                                Board Paper of {list.paper}
                              </div>
                              <div>
                                <ul className="horizontal-list">
                                  <li>Year: {list.year}</li>
                                  <li>Board: {list.board}</li>
                                  <li>Medium: {list.medium}</li>
                                  <li>Standard: {list.standard}</li>
                                  <li>Comment: {list.total_comments}</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="qpdc-right">
                            <div
                              className="question-paper-list-icon"
                              onClick={() => handleView(list)}
                            >
                              <Icon name="passwordEye" />
                            </div>
                            <div className="vertical-line"></div>
                            <div
                              className="question-paper-list-icon downlaod"
                              onClick={
                                userName
                                  ? () => handleDownload(list)
                                  : handleClickOpen
                              }
                            >
                              <Icon name="download" />
                            </div>

                            {/* <div className="vertical-line"></div>
                            <div className="question-paper-list-icon">
                              <Icon name="share" />
                            </div> */}
                          </div>
                        </div>
                        {/* show the advertisement after every four paper */}
                        {isMultipleOfFour && (
                          // <div className="advertisement-container">
                          <Adsense
                            style={{ display: "flex", height: "90px " }}
                            slot="1234567890"
                          />
                          // </div>
                        )}
                        {/* if papers are less then four then show the advertisement after the last paper  */}
                        {isFirstFour && (
                          // <div className="advertisement-container">
                          <Adsense
                            style={{ display: "flex", height: "90px " }}
                            slot="1234567890"
                          />
                          // </div>
                        )}
                      </Fragment>
                    );
                  })
                ) : (
                  <div className="no-data-found-container">No Data Found</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginPopup
        open={open}
        onClose={handleClose}
        url={"/dashboard/questionpaper-list"}
      />
    </Fragment>
  );
};

export default QuestionPaperList;
