// Object containing authentication endpoints.

export const authEndpoits = {
  signin: "/auth/sign_in",
  signup: "/auth/sign_up",
  forgetPassword: "/auth/forgot_password",
  resetPassword: "/auth/reset_password",
  countryCode: "/country",
  board: "/board",
  standard: "/standard",
  medium: "/medium",
  socialLogin: "/auth/social_login",
  listPapers: "/paper",
  userSubjects: "/subject",
  updates: "/updates",
  userMe: "/user/me",
  downloadPaper: "/paper/download",
  commet: "/comment",
  userProfile: "/user/profile",
  userProfileData: "/user",
  paperView: "/paper/view",
  uploadProfilePicture: "/user/profile_picture",
  randomPaper: "/paper/display",
};
