import { TibButton } from "../button/button";
import Icon from "../icon/icon";
import "../auth-sidebar/auth-sidebar.scss";
import image from "../../../assets/sidebarimage.svg";

// AuthSideBar component
export const AuthSideBar = (props) => {
  // Extract the title from props or set a default value
  const title = props.title ? props.title : "Sign in for";
  const isSignIn = props.isSignIn;

  /**
   * Function to go back in the browser history
   */
  function goBack() {
    window.history.back();
  }

  // Render the AuthSideBar component
  return (
    <div className="auth-sidebar ">
      <div>
        <div id="header">
          <div className="auth-sidebar-back-btn-container">
            {/* Button to go back */}
            {!isSignIn && (
              <TibButton
                type="contained"
                className="link-button"
                onClick={goBack}
                startIcon={<Icon name="backArrow" />}
              >
                Back
              </TibButton>
            )}
          </div>
          <div className="header auth-sidebar-header-margin ">
            {/* Title and subtitle */}
            <span className="auth-sidebar-title">
              {title} the QuesTank <br />
              Now!!
            </span>
          </div>
        </div>
        <div className="sibar-image-container">
          <img src={image} alt="" />
        </div>
      </div>
    </div>
  );
};
